import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInitialize } from '../actions/action_auth';
import Loadingpage from '../pages/loadingpage';
import { useLocation, useNavigate} from 'react-router';

export default function JwtProvider({ children }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const isSocialRoute = location.pathname.includes('social')
    const {loading, errors} = useSelector(state => state.user);
    useEffect(() => {
      // console.log("jwt provider")
      if(!isSocialRoute){

        dispatch(getInitialize());
      }
    }, [isSocialRoute])
    // if(loading && !isSocialRoute) return <Loadingpage />
    if(loading && !isSocialRoute) return <div className="loader"></div>;
    // if(errors) return navigate('/signin')
  return (
    <>{ children }</>
  )
}
