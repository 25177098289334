import React, { useEffect, useState } from "react";
import {
  Modal,
  Table,
  TableHeader,
  Grid,
  Input,
  Button,
  Dropdown,
} from "semantic-ui-react";
import CreateSelect from "react-select/creatable";
import axios from "../../api/axiosInstance";
import { getHeader } from "../../types";
import { useDebounce } from "../../customHooks";
import Swal from "sweetalert2";

const fetchData = async (poolId) => {
  const body = {
    poolId: poolId,
    talentName: "",
    userName: "",
    clientName: "",
  };
  try {
    const response = await axios.get(
      `api/talentpool/custompool/shared-to/results/${poolId}`,
      { headers: getHeader() }
    );
    return response.data;
  } catch (e) {
    console.error("Error fetching data: ", e);
    return null;
  }
};

const postData = async (emailids, poolId) => {
  const body = {
    emails: emailids,
  };
  try {
    const response = await axios.post(
      `api/talentpool/custompool/share-pool-to-users/${poolId}`,
      body,
      { headers: getHeader() }
    );
    return response.data;
  } catch (e) {
    console.error("Error fetching data: ", e);
    return null;
  }
};

const fetchUserOptions = async (searchQuery) => {
  try {
    const response = await axios.get(
      `/api/user/shared-to/search?searchQuery=${searchQuery}`,
      { headers: getHeader() }
    );
    return response.data;
  } catch (e) {
    console.error("Error fetching data: ", e);
    return [];
  }
};

export default function SharedToModal({ open, close, poolId }) {
  const [data, setData] = useState([]);
  const [optionValue, setOptionValue] = useState({
    searchQuery: "",
    value: [],
    options: [],
  });
  const [loading, setLoading] = useState(false);
  const [lastValidQuery, setLastValidQuery] = useState("");

  const debounceSearchQuery = useDebounce(optionValue.searchQuery);

  useEffect(() => {
    fetchData(poolId).then((data) => setData(data));
  }, [poolId]);

  useEffect(() => {
    if (
      debounceSearchQuery.startsWith(lastValidQuery) &&
      lastValidQuery !== ""
    ) {
      console.log(`Skipping API call for: ${debounceSearchQuery}`);
      return;
    }
    fetchUserOptions(debounceSearchQuery).then((data) => {
      if (data.length > 0) {
        let optionLists = data.map((option) => {
          return {
            key: option.id,
            label: option.name,
            value: option.email,
          };
        });
        setOptionValue({
          ...optionValue,
          options: optionLists,
        });
      } else {
        setLastValidQuery(debounceSearchQuery);
      }
    });
  }, [debounceSearchQuery]);

  const handleChange = (value) => {
    console.log("handleChange", value);
    setOptionValue({ ...optionValue, searchQuery: "", value });
    setLastValidQuery("");
  };

  const handleSearchChange = (searchQuery) => {
    console.log("handleSearchChange", searchQuery);

    setOptionValue({
      ...optionValue,
      searchQuery: searchQuery,
    });
  };

  const isValidNewOption = (inputValue, selectValue, selectOptions) => {
    //     //only accept email addresses as new options
    if (inputValue.includes("@")) {
      return true;
    }
  };

  const onSumbit = () => {
    let valueModified = optionValue.value.map((option) => option.value);
    setLoading(true);
    postData(valueModified, poolId).then((data) => {
      console.log("Data posted", data);
      Swal.fire({
        title: "Pool(s) has been shared",
        timer: 2000,
      });
    }).finally(() => {
        setLoading(false);
    })
  };

  console.log("setOptionValue", optionValue);

  return (
    <Modal open={open} onClose={close} size="tiny">
      <Modal.Header style={{ color: "var(--text) !important" }}>
        Share
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              {lastValidQuery !== "" && (
                <p style={{ color: "var(--text)" }}>
                  <i>Note: Only email addresses are allowed</i>
                </p>
              )}
            </Grid.Column>
            <Grid.Column width={12}>
              <CreateSelect
                isMulti
                onChange={handleChange}
                onInputChange={handleSearchChange}
                options={optionValue.options || []}
                placeholder="State"
                value={optionValue.value}
                isValidNewOption={isValidNewOption}
              />

              {/* <Dropdown
                            fluid
                            multiple
                            onChange={handleChange}
                            onSearchChange={handleSearchChange}
                            options={optionValue.options || []}
                            placeholder='State'
                            search
                            searchQuery={optionValue.searchQuery}
                            allowAdditions
                            selection
                            value={optionValue.value}
                        /> */}
              {/* <Input fluid placeholder="Search by name to share access" /> */}
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                fluid
                onClick={onSumbit}
                style={{ backgroundColor: "var(--green)", color: "#fff" }}
                loading={loading}
              >
                Share
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Table style={{ color: "var(--text) !important" }}>
                <TableHeader>
                  <Table.Row style={{ color: "var(--text) !important" }}>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Company</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </TableHeader>
                <Table.Body>
                  {data &&
                    data.map((shared) => {
                      return (
                        <Table.Row key={shared.name}>
                          <Table.Cell>{shared.name}</Table.Cell>
                          <Table.Cell>{shared.clientName}</Table.Cell>
                          <Table.Cell>
                            <Button outline>Shared</Button>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}
