import React from "react";
import { Button, Card, Icon, Image, Modal } from "semantic-ui-react";
import { useSelector } from "react-redux";
import Resu from "../../../../candidate/cand_resume";
// import Resume from "../../Talent-Card/BasicInfo/Images/resume1.png";
import EditResumeModal from "./EditResumeModal";
import ResumeImage from "./resume-image.svg";
import { useParams } from "react-router-dom";

function ModalExampleModal() {
  const [open, setOpen] = React.useState(false);
  const unlockedTalent = useSelector(state => state.application?.unlockedTalent)
  const { individual_candidate } = useSelector((state) => state.application);
  const { role } = useSelector((state) => state.user.user);
  const { cand_id } = useParams();

  const userRole = useSelector((state) => state.user.user);
  const handleOpenModal = () => {
    if (role === 'partnerClient' && !unlockedTalent?.includes(cand_id)) {
      return;
    }
    setOpen(true);
  }
  const showPopup = role === 'partnerClient' && !unlockedTalent?.includes(cand_id);
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={handleOpenModal}
      open={open}
      size="large"
      closeIcon
      style={{ marginTop: "5%" }}
      trigger={
        <Button
          style={{
            padding: "7px",
            borderRadius: "5px",
            border: "1px solid var(--Green, #21C8AA)",
            background: "#D0F7F9",
            color: "#003458",
            // filter: role === 'partnerClient' && !unlockedTalent?.includes(cand_id) ? 'blur(4px)' : 'none',
            cursor: role === 'partnerClient' && !unlockedTalent?.includes(cand_id) ? 'not-allowed' : 'pointer'
          }}
          disabled={showPopup}
        >
          <Icon>
            <Image src={ResumeImage} style={{ height: "13px" }} />
          </Icon>
          Resume
        </Button>
      }
    >
      <Modal.Content>
        <Modal.Description>
          {role == "Admin" && !individual_candidate.bio.resumeURL? (
            <EditResumeModal talentId={individual_candidate.bio._id} />
          ) : null}
          <br />
         
          {
            individual_candidate.bio.resumeURL && 
            <a
            className="open-in-new-tab-btn"
            style={{ fontWeight: "600" }} 
            href={`https://docs.google.com/gview?url=${encodeURIComponent(individual_candidate.bio.resumeURL)}&embedded=true&refresh=true`} 
            target="_blank" 
            rel="noopener noreferrer">
            <u>Open in new tab</u>
          </a>
          // <a style={{fontWeight:"600"}} href={`https://docs.google.com/gview?url=${encodeURIComponent(individual_candidate.bio.resumeURL)}&embedded=true`} target="_blank" rel="noopener noreferrer"><u>Open in new tab</u></a>
//  <iframe 
//   src={`https://docs.google.com/gview?url=${encodeURIComponent(individual_candidate.bio.resumeURL)}&embedded=true`} 
//   width="100%" 
//   height="600px">
// </iframe> 
          }
          {/* </button> */}
          {console.log("look here individual_candidate.bio.resumeURL", individual_candidate.bio.resumeURL)}
          <Resu resume={individual_candidate.bio.resumeURL} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default ModalExampleModal;
