import axios from "axios";
import { getHeader } from "./../types.js";
import { getAllClientUsers } from "../actions/action_client.js";

// axios.defaults.baseURL = "https://dev-hireapi.highpo.co";

export default {
  user: {
    signup: (data) =>
      axios.post("api/auth/signup", data).then((res) => res.data),
    signin: (data) => {
      console.log("api", data);
      return axios.post(`/api/auth/login`, data).then((res) => {
        localStorage.setItem("brandingPopUpClosed", false)
        return res.data
      });
    },
    getInitialize: (data) => {
      return axios.post("/api/auth/me", {token: data}, { headers: getHeader()}).then((res) => res.data);
    },
    getEmailApi: (token) =>
      axios.get(`/api/auth/email/${token}`).then((res) => res.data),
    signupMemberApi: (data) =>
      axios
        .post("/api/auth/addmemeber", data, { headers: getHeader() })
        .then((res) => res.data),
        
    ChangePassApi: (data, email) =>
      axios
        .post(`/api/auth/changepassword/${email}`, data, {
          headers: getHeader(),
        })
    .then((res) => res.data),
    submitForgotPasswordApi: (email) =>
      axios.post(`/api/auth/forgotpassword`, email).then((res) => res.data),
    submitSetForgotPasswordApi: (data, token) =>
      axios
        .post(`/api/auth/setforgotpassword/${token}`, data)
        .then((res) => res.data),
    getAllUsersApi: (company_id) =>
      axios
        .get(`/api/auth/allUsers/${company_id}`, { headers: getHeader() })
        .then((res) => res.data),
    getAllClientUsersApi: (client_id) =>
      axios
        .get(`/api/auth/users-of-client/${client_id}`, { headers: getHeader() })
        .then((res) => res.data),
    logoutApi: () => 
        axios.get('/api/auth/logout')
        .then((res) => res.data),
  },
};
