import {
  SET_FETCHED_CANDIDATE_BY_JOB_ID,
  SET_APPLICATIONS,
  SET_CANDIDATE_FILTER,
  SET_SCHEDULE_NOW,
  CANCEL_INTERVIEW,
  SET_FETCH_CAND_TALENT_POOL,
  SET_MOVED_CANDIDATES_STAGE,
  SET_CANDIDATE_EDIT_BY_ADMIN,
  CREATE_QRATA_NOTES,
  DELETE_QRATA_NOTES,
  SET_QRATA_NOTES,
  ADD_REFERENCE_INFO,
  TAKE_REFERENCE_INFO_QUESTION,
  CREATE_NOTES,
  EDIT_NOTES,
  DELETE_NOTES,
  LOADING_API,
  CONTENT_LOADING,
  ERROR_UPDATING_DATA,
  ADD_CANDIDATE_FEEDBACK,
  ADD_CANDIDATE_DOCUMENT,
  DELETE_CANDIDATE_DOCUMENT,
  EDITED_WORKEXPERIENCE,
  EDITED_EDUCATION,
  IS_QA_AND_CURATED_PROFILE,
  EDITED_RESUME,
  SCORECARD_TEMPLATE,
  GETSCORECARD_TEMPLATE,
  UPDATESCORECARD_TEMPLATE,
  PERSONALITY_TRAITS,
  CURATION_DATA,
  TALENT_GPT,
  CREATE_INTERNAL_NOTES,
  DELETE_INTERNAL_NOTES,
  QRATA_FIT,
  GET_CHATEDUSERS,
  All_Chats,
  CLIENT_INTERNAL_NOTES,
  DELETE_CLIENT_NOTES,
  STABILITY_RATING,
  INTERVIEW_LIST,
  REQUEST_SUBMIT,
  ADD_TALENT_SCORECARD,
  GET_TALENT_SCORECARD,
  GET_UNLOCKED_TALENTS,
  UNLOCK_TALENT,
  REMAINING_UNLOCKS,
  GET_MARKETINSIGHTS,
  ALL_MAPPED_CANDIDATE,
  GET_MAPPED_CANDIDATE,
} from "./../types";
import api from "./../api/application_api";
import FormData from "form-data";
import axios from "axios";
import Scorecard from "../pages/scorcard";

export function setFetchedCandidateByJobId(data) {
  return {
    type: SET_FETCHED_CANDIDATE_BY_JOB_ID,
    data,
  };
}

export function setApplications(data) {
  return {
    type: SET_APPLICATIONS,
    data,
  };
}

export function setAddDocument(data) {
  return {
    type: ADD_CANDIDATE_DOCUMENT,
    data,
  };
}

export function setCandidateFilter(data) {
  return {
    type: SET_CANDIDATE_FILTER,
    data,
  };
}

export function setSchedulenow(data) {
  return {
    type: SET_SCHEDULE_NOW,
    data,
  };
}

export function setFetchedCandidateTalentPool(data) {
  return {
    type: SET_FETCH_CAND_TALENT_POOL,
    data,
  };
}

export function createQrataNotesReducer(data) {
  console.log(" -----------------------------", data);

  return {
    type: CREATE_QRATA_NOTES,
    data,
  };
}

export function setQrataNotes(data) {
  console.log("setQrataNotes -----------------------------setQrataNotes", data);

  return {
    type: SET_QRATA_NOTES,
    data,
  };
}

// getting filter content on canidate page
export const fetchFilterContent = (job_id) => async (dispatch) => {
  const data = await api.application.fetchFilterContentApi(job_id);
  return dispatch(setCandidateFilter(data));
};

export function setMovedCandidatesStage(data) {
  return {
    type: SET_MOVED_CANDIDATES_STAGE,
    data,
  };
}
// creating job title and job description
export const fetchCandidateByJobId = (job_id, app_id) => (dispatch) => {
  console.log("action on job", job_id, app_id);
  dispatch({
    type: LOADING_API,
  });
  return api.application
    .fetchCandidateByJobIdApi(job_id, app_id)
    .then((data) => {
      console.log("action on job", data);
      return dispatch(setFetchedCandidateByJobId(data));
    });
};
// saving note
export const savingNotes = (note_params, note) => (dispatch) => {
  dispatch({
    type: CONTENT_LOADING,
  });
  console.log("save action", note_params, note);
  return api.application
    .savingNotesApi(note_params, note)
    .then((data) => {
      return dispatch({
        type: CREATE_NOTES,
        data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: ERROR_UPDATING_DATA,
        error,
      });
    });
};
// editing note
export const editingNote = (note_params, comment) => (dispatch) => {
  return api.application.editingNotesApi(note_params, comment).then((data) => {
    return dispatch({
      type: EDIT_NOTES,
      data,
    });
  });
};
// deleting note
export const deletingNote = (note_params) => (dispatch) => {
  dispatch({
    type: CONTENT_LOADING,
  });
  return api.application
    .deletingNotesApi(note_params)
    .then((data) => {
      return dispatch({
        type: DELETE_NOTES,
        data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ERROR_UPDATING_DATA,
        error,
      });
    });
};

// filter candidate
export const filterCandidateApplications = (data, id) => (dispatch) => {
  // console.log("action", data)
  let final_object = {};
  if (data.candidate_locations.length > 0) {
    final_object = Object.assign({}, final_object, {
      candidate_locations: data.candidate_locations,
    });
  }
  if (data.candidate_experience.length > 0) {
    final_object = Object.assign({}, final_object, {
      candidate_experience: data.candidate_experience,
    });
  }
  if (data.candidate_salary.length > 0) {
    final_object = Object.assign({}, final_object, {
      candidate_salary: data.candidate_salary,
    });
  }
  if (data.freshness) {
    final_object = Object.assign({}, final_object, {
      freshness: Number(data.freshness),
    });
  }
  if (data.notice_period) {
    final_object = Object.assign({}, final_object, {
      notice_period: Number(data.notice_period),
    });
  }

  // console.log('----------', final_object)
  return api.application
    .filterCandidateApplicationsApi(final_object, id)
    .then((data) => {
      // console.log('ACTION COMPARE 2', data)
      return dispatch(setApplications(data));
    });
};

// save documents
export const saveDocumentNow = (data) => {
  return (dispatch) => {
    // console.log("))))))))))))))))))))))))))))",data)
    let headers = "";
    headers = {
      Accept: "application/json",
    };

    const fd = new FormData();
    fd.append("document", data.document);
    fd.append("comment", data.document_comment);
    fd.append("user_id", data.user_id);

    console.log("+++++++++++++000", fd);
    return new Promise((resolve, reject) => {
      return axios
        .post(
          `/api/application/document/${data.job_id}/${data.app_id}/${data.user_id}`,
          fd,
          headers
        )
        .then(
          (response) => {
            console.log("===============", response);
            console.log("===============", response.data);
            dispatch(setAddDocument(response.data));
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  };
};
// scheduling an candidate's interview

export const ScheduleNow = (data, job_id, cand_id, user_id) => (dispatch) => {
  dispatch({
    type: CONTENT_LOADING,
  });

  return api.application
    .setInterviewApi(job_id, cand_id, user_id, data)
    .then((data) => {
      console.log("data from schedule action", data);
      dispatch(setSchedulenow(data));
    })
    .catch((error) => {
      dispatch({
        type: ERROR_UPDATING_DATA,
        error,
      });
    });
};

// talent pool seeing individual candidate
export const fetchCandidateTalentPool = (id) => (dispatch) => {
  console.log("+++++++++++++", id);
  dispatch({
    type: LOADING_API,
  });
  return api.application
    .fetchCandidateTalentPoolApi(id)
    .then((data) => {
      console.log("fetch data +++++++++++++++++++++++++++++++++:", data);
      return dispatch(setFetchedCandidateTalentPool(data));
    })
    .catch((error) => {
      console.log("action errror", error);
      return error;
    });
};

// cancel interview
export const cancelInterview = (interview_id, job_id, app_id) => (dispatch) => {
  dispatch({
    type: CONTENT_LOADING,
  });
  return api.application
    .cancelInterviewApi(interview_id, job_id, app_id)
    .then((data) => {
      return dispatch({
        type: SET_SCHEDULE_NOW,
        data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ERROR_UPDATING_DATA,
        error,
      });
    });
};

export const deleteInterview = (interview_id, job_id, app_id) => (dispatch) => {
  dispatch({
    type: CONTENT_LOADING,
  });
  return api.application
    .deleteInterviewApi(interview_id, job_id, app_id)
    .then((data) => {
      return dispatch({
        type: SET_SCHEDULE_NOW,
        data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ERROR_UPDATING_DATA,
        error,
      });
    });
};

export const editAdminBasicInfo = (data, talentId, resume) => (dispatch) => {
  dispatch({
    type: CONTENT_LOADING,
  });
  console.log("action called", data, talentId);
  if (resume) {
    api.application.resumeUploadApi(resume, talentId);
  }
  return api.application

    .adminUpdateTalentApi(data, talentId)
    .then((candidate_data) => {
      console.log("api data from backend", candidate_data, candidate_data.typeOfEmployment
      );
      const basic_details = {
        _id: candidate_data._id,
        fullName: candidate_data.firstName + " " + candidate_data.lastName,
        firstName: candidate_data.firstName,
        lastName: candidate_data.lastName,
        email: candidate_data.email,
        contactNumber: candidate_data.contactNumber,
        summary: candidate_data.summary,
        skills: candidate_data.skills,
        currentDesignation: candidate_data.currentDesignation,
        currentCompany: candidate_data.currentCompany,
        currentSalary: candidate_data.currentSalary,
        currentLocation: candidate_data.currentLocation,
        currentCurrencyType: candidate_data.currentCurrencyType,
        preferenceCurrencyType: candidate_data.preferenceCurrencyType,
        preferredDesignation: candidate_data.preferredDesignation,
        preferredLocation: candidate_data.preferredLocation,
        preferredSalary: candidate_data.preferredSalary,
        experience: candidate_data.experience,
        noticePeriod: candidate_data.noticePeriod,
        typeCompanyPreference: candidate_data.typeCompanyPreference,
        typeOfEmployment: candidate_data.typeOfEmployment,
        top3Skills: candidate_data.top3Skills,
        workExperience: candidate_data.workExperience,
        education: candidate_data.education,
        educationResume: candidate_data.parsedResumeData.educationResume,
        workLinks: candidate_data.workLinks,
        workExperienceResume: candidate_data.parsedResumeData.workExperienceResume,
        communicationSkills: candidate_data.communicationSkills,
        resumeURL: candidate_data.resumeURL,
        searchTag: candidate_data.searchTag,
        potentials: candidate_data.potentials,
        role: candidate_data.role,
        additionalCompensationNotes: candidate_data.additionalCompensationNotes,
        additonalExpectedCompensationNotes:
          candidate_data.additonalExpectedCompensationNotes,
        timeCommitment: candidate_data.timeCommitment,
        timeCommitmentType: candidate_data.timeCommitmentType,
      };
      return dispatch({
        type: SET_CANDIDATE_EDIT_BY_ADMIN,
        basic_details,
      });
    })
    .catch((error) => {
      dispatch({
        type: ERROR_UPDATING_DATA,
        error,
      });
    });
};

export const createQrataNotes = (data, talentId) => async (dispatch) => {
  console.log("data of qratanotes", data);
  dispatch({
    type: CONTENT_LOADING,
  });
  await api.application
    .saveQrataNotesApi(data, talentId)
    .then((data) => dispatch(createQrataNotesReducer(data)))
    .catch((error) => {
      console.log("error", error);
      return dispatch({
        type: ERROR_UPDATING_DATA,
        error,
      });
    });
};

export const editQrataNotes = (data, talentId, qrataNotesID) => (dispatch) => {
  console.log("edited data of qratanotes", data, talentId, qrataNotesID);
  api.application
    .editQrataNotesApi(data, talentId, qrataNotesID)
    .then((data) => dispatch(setQrataNotes(data)));
};

export const deleteQrataNotes =
  (data, talentId, qrataNotesID) => (dispatch) => {
    console.log("delete of qratanotes", data, talentId, qrataNotesID);
    api.application.deleteQrataNotesApi(data, talentId, qrataNotesID).then(
      dispatch({
        type: "DELETE_QRATA_NOTES",
        qrataNotesID,
      })
    );
  };

export const addReferenceInfo = (data, talentId) => (dispatch) => {
  dispatch({
    type: CONTENT_LOADING,
  });
  return api.application.addReferenceInfoApi(data, talentId).then((data) =>
    dispatch({
      type: ADD_REFERENCE_INFO,
      data,
    })
  );
};

export const isCuratedProfile = (data, talentId) => (dispatch) => {
  dispatch({
    type: CONTENT_LOADING,
  });
  api.application.curatedProfileApi(data, talentId).then((data) =>
    dispatch({
      type: IS_QA_AND_CURATED_PROFILE,
      data,
    })
  );
};
export const isQaProfile = (data, talentId) => (dispatch) => {
  dispatch({
    type: CONTENT_LOADING,
  });
  api.application.qaProfileApi(data, talentId).then((data) =>
    dispatch({
      type: IS_QA_AND_CURATED_PROFILE,
      data,
    })
  );
};
export const takeReferenceInfo = (data, talentId, refId) => (dispatch) => {
  dispatch({
    type: CONTENT_LOADING,
  });
  api.application.takeReferenceInfoApi(data, talentId, refId).then((data) =>
    dispatch({
      type: TAKE_REFERENCE_INFO_QUESTION,
      data: {
        data,
        refId,
      },
    })
  );
};

export const addCandidateFeedback = (data, talentId) => (dispatch) => {
  api.application.addCandidateFeedbackApi(data, talentId).then((data) =>
    //    {
    //   return dispatch(setFetchedCandidateTalentPool(data))
    // }
    dispatch({
      type: ADD_CANDIDATE_FEEDBACK,
      data,
    })
  );
};

export const moveCandidate = (job_id, app_id, stage) => (dispatch) => {
  let newStage = {};
  if (stage === "applied") {
    newStage.applied = true;
  } else if (stage === "added") {
    newStage.applied = true;
  } else if (stage === "curated") {
    newStage.curated = true;
  } else if (stage === "shortlisted") {
    newStage.shortlisted = true;
  } else if (stage === "interviewed") {
    newStage.interviewed = true;
  } else if (stage === "offered") {
    newStage.offered = true;
  } else if (stage === "hired") {
    newStage.hired = true;
  }

  console.log("application stage", newStage);
  return api.application
    .moveCandidateStageApi(job_id, app_id, newStage)
    .then((data) => {
      console.log("data from move", data);
      return dispatch(setMovedCandidatesStage(newStage));
    });
};

export const EditWorkExperience =
  (data, talentId, workExperienceId) => (dispatch) => {
    console.log("bjhjvjv", data, talentId, workExperienceId);
    return api.application
      .editWorkExperienceApi(data, talentId, workExperienceId)
      .then((data) => {
        return dispatch({
          type: EDITED_WORKEXPERIENCE,
          data,
        });
      });
  };

export const EditPotentialAnalysis = (data, talentId) => (dispatch) => {
  console.log("vinod --EditPotentialAnalysis", data, talentId);
  dispatch({
    type: CONTENT_LOADING,
    data,
  });
  return api.application
    .editPotentialAnalysisApi(data, talentId)
    .then((data) => {
      return dispatch({
        type: SET_FETCH_CAND_TALENT_POOL,
        data,
      });
    });
};

export const addDocuments = (data, talent_id) => (dispatch) => {
  return api.application
    .commanDocumetsUploadApi(data, talent_id)
    .then((data) => {
      return dispatch({
        type: SET_FETCH_CAND_TALENT_POOL,
        data,
      });
    });
};

export const editWorkSample = (data, talent_id) => (dispatch) => {
  console.log("data eeee", data, "talent_id", talent_id)
  return api.application
    .editWorkSamplesApi(data, talent_id)
    .then((data) => {
      console.log("this is the data from editWorkSample : ", data)
      return dispatch({
        type: SET_FETCH_CAND_TALENT_POOL,
        data
      });
    });
};

export const deleteWorkSample = (data) => (dispatch) => {
  return api.application.deleteWorkSampleApi(data).then((data) => {
    return dispatch({
      type: SET_FETCH_CAND_TALENT_POOL,
      data,
    });
  });
};

// ----------------WORK SAMPLE START-------------------------
export const addProject = (data, talent_id) => (dispatch) => {
  return api.application
    .addProjectApi(data, talent_id)
    .then((data) => {
      return dispatch({
        type: SET_FETCH_CAND_TALENT_POOL,
        data,
      });
    });
};

export const editProject = (data, talent_id) => (dispatch) => {
  console.log("data eeee edit project", data, "talent_id", talent_id)
  return api.application
    .editProjectsApi(data, talent_id)
    .then((data) => {
      console.log("this is the data from editProject : ", data)
      return dispatch({
        type: SET_FETCH_CAND_TALENT_POOL,
        data
      });
    });
};

export const deleteProject = (data) => (dispatch) => {
  console.log("see data for delete from action : ", data)
  return api.application.deleteProjectApi(data).then((data) => {
    return dispatch({
      type: SET_FETCH_CAND_TALENT_POOL,
      data,
    });
  });
};
// ----------------WORK SAMPLE END-------------------------


export const addCertificate = (data, talent_id) => (dispatch) => {
  return api.application.certificateUploadApi(data, talent_id).then((data) => {
    return dispatch({
      type: SET_FETCH_CAND_TALENT_POOL,
      data,
    });
  });
};

export const addProfilePhoto = (data, talent_id) => (dispatch) => {
  return api.application.profilePhotoUploadApi(data, talent_id).then((data) => {
    return dispatch({
      type: SET_FETCH_CAND_TALENT_POOL,
      data,
    });
  });
};

export const deleteCertificate = (data) => (dispatch) => {
  return api.application.deleteCertificateApi(data).then((data) => {
    return dispatch({
      type: SET_FETCH_CAND_TALENT_POOL,
      data,
    });
  });
};

export const addCandidateDocument = (data, talent_id) => (dispatch) => {
  return api.application
    .candidateDocumentUploadApi(data, talent_id)
    .then((data) => {
      return dispatch({
        type: SET_FETCH_CAND_TALENT_POOL,
        data,
      });
    });
};

export const deleteCandidateDocument = (data) => (dispatch) => {
  return api.application.deleteCandidateDocumentApi(data).then((data) => {
    return dispatch({
      type: SET_FETCH_CAND_TALENT_POOL,
      data,
    });
  });
};

export const EditEducation = (data, talentId, educationId) => (dispatch) => {
  console.log("educationkjkjkj", data, talentId, educationId);
  return api.application
    .editEducationApi(data, talentId, educationId)
    .then((data) => {
      return dispatch({
        type: EDITED_EDUCATION,
        data,
      });
    });
};

export const EditResumeUpload = (resume, talentId) => (dispatch) => {
  return api.application.resumeUploadApi(resume, talentId).then((data) => {
    console.log("resume api successs data", data);
    return dispatch({
      type: EDITED_RESUME,
      data,
    });
  });
  // return dispatch({
  //   type: EDITED_RESUME,
  //   data,
  // });
};

export const Addscorecard = (data) => (dispatch) => {
  return api.application.addScoreCard(data).then((data) => {
    return dispatch({
      type: SCORECARD_TEMPLATE,
      data,
    });
  });
};

export const Getscorecard = (data) => (dispatch) => {
  return api.application.getScoreCard(data).then((data) => {
    return dispatch({
      type: GETSCORECARD_TEMPLATE,
      data,
    });
  });
};

export const Updatescorecard = (data, Id) => (dispatch) => {
  return api.application.updateScoreCard(data, Id).then((data) => {
    return dispatch({
      type: UPDATESCORECARD_TEMPLATE,
      data,
    });
  });
};

export const Personalitydata = (Id) => (dispatch) => {
  return api.application.personalityTraits(Id).then((data) => {
    return dispatch({
      type: PERSONALITY_TRAITS,
      data,
    });
  });
};

export const Curationdata = (jobId, candId) => (dispatch) => {
  return api.application.curationData(jobId, candId).then((data) => {
    const job_questions_answers = data.job?.job_questions_answers;
    console.log("curationapi hitted", data, job_questions_answers);

    return dispatch({
      type: CURATION_DATA,
      data,
    });
  });
};

export const TalentGPT = (Id) => (dispatch) => {
  return api.application.talentGPT(Id).then((data) => {
    return dispatch({
      type: TALENT_GPT,
      data,
    });
  });
};

export const getQrataFit = (jobId, candId, qratafit) => (dispatch) => {
  return api.application.qrataFit(jobId, candId, qratafit).then((data) => {
    return dispatch({
      type: QRATA_FIT,
      data,
    });
  });
};

export const changeSubStatus = (jobId, appId, data) => (dispatch) => {
  return api.application.changeSubStatusApi(jobId, appId, data).then((data) => {
    return dispatch({
      type: "SUB_STATUS_CHANGE",
      data: { appId, ...data },
    });
  });
};
// export const getAllchat = (email) => (dispatch) => {
//   return api.application.getChatedUser(email).then((response) => {
//     console.log(response, "error from chats1");
//     if (!response) {
//       console.log(response, "error from chats if");
//       return dispatch({
//         type: GET_CHATEDUSERS,
//         error: "Error occurred",
//       });
//     }
//     return dispatch({
//       type: GET_CHATEDUSERS,
//       data: response.data,
//     });
//   });
// };
export const getAllchat = (email) => async (dispatch) => {
  try {
    const response = await api.application.getChatedUser(email);
    console.log(response, "error from chats1");

    if (!response) {
      console.log(response, "error from chats if");
      return dispatch({
        type: GET_CHATEDUSERS,
        error: "Error occurred",
      });
    }

    return dispatch({
      type: GET_CHATEDUSERS,
      data: response.data,
    });
  } catch (error) {
    console.error("Error in getAllchat:", error);
    return dispatch({
      type: GET_CHATEDUSERS,
      error: "Error occurred",
    });
  }
};

// export const getAllChat = (email) => (dispatch) => {
//   return api.application.getChatedUser(email).then((response) => {
//     // Check if the response has an 'error' property

//     if (response.data.error) {
//       return dispatch({
//         type: GET_CHATEDUSERS,
//         error: response.data.error,
//       });
//     }
//     return dispatch({
//       type: GET_CHATEDUSERS,
//       data: response.data,
//     });
//   });
// };

export const Messages = (senderEmail, receiverEmail) => (dispatch) => {
  return api.application.chatsData(senderEmail, receiverEmail).then((data) => {
    return dispatch({
      type: All_Chats,
      data,
    });
  });
};

export const createInternalNotes = (marketId, applicantId, data) => (dispatch) => {
  return api.application.internalNotes(marketId, applicantId, data).then((data) => {
    return dispatch({
      type: CREATE_INTERNAL_NOTES,
      data,
    });
  });
};

export const deleteInternalNotes = (candId, notesId) => (dispatch) => {
  return api.application.deleteInternalNotes(candId, notesId).then((data) => {
    return dispatch({
      type: DELETE_INTERNAL_NOTES,
      data,
    });
  });
};

export const createClientNotes = (jobId, appId, data) => (dispatch) => {
  return api.application.clientNotes(jobId, appId, data).then((data) => {
    return dispatch({
      type: CLIENT_INTERNAL_NOTES,
      data,
    });
  });
};

export const deleteClientNotes = (jobId, appId, noteId) => (dispatch) => {
  return api.application
    .deleteClientNotes(jobId, appId, noteId)
    .then((data) => {
      return dispatch({
        type: DELETE_CLIENT_NOTES,
        data,
      });
    });
};

export const stability = (candId) => (dispatch) => {
  return api.application.stabilityRating(candId).then((data) => {
    return dispatch({
      type: STABILITY_RATING,
      data,
    });
  });
};

export const getInterviewList = (data) => (dispatch) => {
  return api.application.interviewList(data).then((data) => {
    return dispatch({
      type: INTERVIEW_LIST,
      data,
    });
  });
};

export const getUnlockedTalents = (clientId) => (dispatch) => {
  return api.application.unlockedTalent(clientId).then((data) => {
    return dispatch({
      type: GET_UNLOCKED_TALENTS,
      data,
    });
  });
};
export const getRemainingUnlock = (clientId) => (dispatch) => {
  return api.application.remainingUnlockApi(clientId).then((data) => {
    return dispatch({
      type: REMAINING_UNLOCKS,
      data,
    });
  });
};

export const unlockTalent = (candidateId, poolId, clientId) => dispatch => {
  return api.application.unlockTalentApi(candidateId, poolId, clientId).then((data) => {
    return dispatch({
      type: UNLOCK_TALENT,
      data,
    });
  });
};

export const setUnlockModal = () => dispatch => {
  return dispatch({
    type: 'SET_OPEN_UNLOCK_MODAL'
  });
};
export const getMaketIntelList = (filter = {}) => (dispatch) => {
  return api.application.marketIntelList(filter).then((data) => {
    return dispatch({
      type: GET_MARKETINSIGHTS,
      data,
    });
  });
};

// export const getMappedCandidates = (data) => (dispatch) => {
//   return api.application.mappedCandidatesList(data).then((data) => {
//     return dispatch({
//       type: ALL_MAPPED_CANDIDATE,
//       data,
//     });
//   });
// };

export const getMappedCandidates = (filters, page, size) => (dispatch) => {
  return api.application.mappedCandidatesList(filters, page, size)
    .then((data) => {
      return dispatch({
        type: ALL_MAPPED_CANDIDATE,
        data,
      });
    });
};

export const getMappedCandidatesById = (id) => (dispatch) => {
  return api.application.mappedCandidatesListByID(id).then((data) => {
    return dispatch({
      type: GET_MAPPED_CANDIDATE,
      data,
    });
  });
};

export const requestSubmitItems =
  (jobId, item, talentId, body) => async (dispatch) => {
    const data = await api.application.requestSubmitApi(
      jobId,
      item,
      talentId,
      body
    );
    return dispatch({
      type: REQUEST_SUBMIT,
      data,
    });
  };

export const addTalentScorecard =
  (summary, scores, jobId, talentId) => async (dispatch) => {
    console.log(summary, talentId, jobId, scores, "dskjhfbhasdj 2");
    const data = await api.application.saveTalentScorecard(
      summary,
      scores,
      jobId,
      talentId
    );
    return dispatch({
      type: ADD_TALENT_SCORECARD,
      data,
    });
  };

export const getTalentScorecard = (jobId, talentId) => async (dispatch) => {
  console.log(jobId, talentId, "dsflk jbn");
  const data = await api.application.talentScorecard(talentId, jobId);
  return dispatch({
    type: GET_TALENT_SCORECARD,
    data,
  });
};
