import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from './paginations'
import CandidateCard from "./candidateCard";
import {
  getAllTalent,
  getAllCustomPool,
} from "../../actions/action_talent_pool";
import { useParams } from "react-router-dom";
import "./talentpool.css";

const initialState = {
  column: null,
  direction: null,
  data: [],
};

function exampleReducer(state, action) {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === "ascending" ? "descending" : "ascending",
        };
      }

      return {
        column: action.column,
        data: state.data,
        direction: "ascending",
      };
    case "SET_DATA":
      return {
        ...state,
        data: action.data,
      };
    default:
      throw new Error("Unknown action type");
  }
}

export default function TalentPoolTable({
  filter,
  emails,
  onCandTalentCheckBox,
  isPublic,
  clientTalentData,
  poolId,
}) {
  const dispatch = useDispatch();

  const { sharedpoolId, publicOrCustom } = useParams();

  const talentData = useSelector((state) => state.talent_pool.talents);
  const userRole = useSelector((state) => state.user.user?.role);
  const loading = useSelector((state) => state.talent_pool.loading);
  const initialFilter = useSelector((state) => state.talent_pool.updatedfilter);
  const page = useSelector((state) => state.talent_pool.page);
  const { isCustomPoolActive, customPoolId } = useSelector(
    (state) => state.talent_pool.customPool
  );
  // useReducer to manage sorting
  const [state, dispatchSort] = useReducer(exampleReducer, initialState);

  useEffect(() => {
    console.log("useEffect", isCustomPoolActive, customPoolId);
    if (isCustomPoolActive) {
      dispatch(getAllCustomPool(customPoolId));
    } else if (publicOrCustom === "custom") {
      dispatch(getAllCustomPool(sharedpoolId));
    } else if (userRole === "Admin") {
      dispatch(getAllTalent(page, filter ? filter : initialFilter));
    }
  }, [dispatch, page, initialFilter, customPoolId]);

  useEffect(() => {
    // Update sorting data when talentData changes
    console.log("problems updating", clientTalentData, talentData)
    if (userRole === "client" && publicOrCustom !== "custom") {
      dispatchSort({ type: "SET_DATA", data: clientTalentData });
    } else {
      dispatchSort({ type: "SET_DATA", data: talentData });
    }
  }, [talentData, clientTalentData]);

  if (loading && !poolId && !clientTalentData) {
    console.log("lengtth loading", loading && !poolId, loading, poolId)
    // return <div>Loading.sdfghjhjgfd..</div>;
    return <div className="loader"></div>;
  }
  console.log("lengtth problem", loading && !poolId, poolId, filter);
  if (state.data?.length < 1 || !state.data) {
    return <div>No talent found</div>;
  }

  const { column, direction, data } = state;

  const isMobile = window.innerWidth < 768;
 
  return (
    <>
    {
      !isMobile ? 
      <div style={{ overflowX: "scroll" }}>
        <div className="talentpool-table" style={{
          gridTemplateColumns: !isPublic ? 
          "50px 100px 2fr 1fr 1fr 1fr 1fr" : 
          "100px 2fr 1fr 1fr 1fr 1fr"
        }}>
          {!isPublic && <div className="table-header"></div>}
          <div className="table-header"></div>
          <div className="table-header">Name</div>
          <div className="table-header">Location</div>
          <div className="table-header">Years of exp.</div>
          <div className="table-header">
            compensation
            <br />
            (current - expected)
          </div>
          <div className="table-header"></div>

          {data.map((talent, index) => (
            <>
              <CandidateCard
                index={index}
                candidate={talent}
                emailIds={emails}
                onCandTalentCheckBox={onCandTalentCheckBox}
                isPublic={isPublic}
                filter={filter}
                poolId={poolId}
              />
            </>
          ))}
        </div>
      </div>
      : 
      <>
        {data.map((talent, index) => (
            <>
              <CandidateCard
                index={index}
                candidate={talent}
                emailIds={emails}
                onCandTalentCheckBox={onCandTalentCheckBox}
                isPublic={isPublic}
                filter={filter}
                poolId={poolId}
              />
            </>
          ))}
      </>
    }
    {!poolId && <Pagination />}
    </>
  );
}
