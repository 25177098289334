import React, { useEffect, useState,useRef   } from 'react';
import { Form, Radio, Divider, Input, Dropdown, Icon } from 'semantic-ui-react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { searchType } from '../../OptionData';
import { locationOptionAction, roleOptionAction, tagOptionAction } from '../../actions/action_option';
import { setFilterValue } from '../../actions/action_talent_pool';

export default function NewTalentFilter({ filter, setFilter, initialFilter }) {

    const dispatch = useDispatch();


    const updateFilter = (updates) => {
        const newFilters = { ...filter, ...updates };
        setFilter(newFilters);
    };


    const handleVisibleTo = (type, value) => {
        updateFilter({
            [type]: [value]
        });
    };

    const resetInputField = () => {
        setFilter(initialFilter);
    };

    const handleDateChange = (event, data) => {
        console.log("datae", data.value);
        if(!data.value) {
            return updateFilter({
                date_range: null,
                created_date: null
            })
        }
        if(data.value.length > 1) {
            updateFilter({ date_range: {
                start: data.value[0],
                end: data.value[1]
            },
         });
        }
    };

    const timeoutRef = useRef(null);

    const handleSearchByName = (e) => {
        const searchValue = e.target.value;

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            updateFilter({ title: searchValue });
        }, 1000);
    };

    const handleCreatedAt = (e, { value }) => {
        console.log('datat', value);
        updateFilter({
            created_date: value
        })
    }

    return (
        <div style={{ border: '1px solid #D9D9D9', borderTop: 'none' }}>
            <div className="filter-header" style={{ padding: '1rem', display: 'flex', alignItems: 'center' }}>
                <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Filters</span>
                
                <span style={{ cursor: 'pointer'}} onClick={resetInputField}><Icon name='refresh'/></span>
            </div>
            <Form>

                <Form.Field style={{ padding: '0 1rem' }}>
                    <Input placeholder="Search by name" onChange={(e)=>handleSearchByName(e)} />
                </Form.Field>

    

                

                <Divider />

                <div style={{ padding: '0 1rem 0.5rem' }}>
                    <label style={{ color: '#003458', fontSize: '1.1rem', fontWeight: 'bold' }}>Created date</label>
                    <Form.Field className='filter-top-padding'>
                        <Radio name="created_date" checked={filter.created_date === 'Today'} onChange={handleCreatedAt} label="Today" value="Today" /> <br />
                        <Radio name="created_date" checked={filter.created_date === 'Last 7 days'} onChange={handleCreatedAt} label="Last 7 days" value="Last 7 days" /> <br />
                        <Radio name="created_date" checked={filter.created_date === 'Last 14 days'} onChange={handleCreatedAt} label="Last 14 days" value="Last 14 days" /> <br />
                        <Radio name="created_date" checked={filter.created_date === 'Custom'} onChange={handleCreatedAt} label="Custom" value="Custom" />

                        {
                        filter.created_date === 'Custom' && 
                        <SemanticDatepicker className='date-range' style={{overflowX:"scroll", width:'70%'}}
                            onChange={handleDateChange}
                            // value={
                            //     filters?.addedDate.length > 0
                            //         ? filters?.addedDate.map(date => (typeof date === 'string' ? new Date(date) : date))
                            //         : null
                            // }
                            type="range"
                            pointing='top'
                        />
                        }
                    </Form.Field>
                </div>

                <Divider />

                <div style={{ padding: '0 1rem 0.5rem' }}>
                    <label style={{ color: '#003458', fontSize: '1.1rem', fontWeight: 'bold' }}>Pool type</label>
                    <Form.Field className="filter-top-padding">
                        <Dropdown
                        className='dropdown-custom'
                            // style={{ marginBottom: '1rem', border:'1px solid grey', padding:'9px 13px', width:'100%', justifyContent:'space-between', display:'flex' }}
                            // selection
                            placeholder="Select"
                            options={searchType}
                            value={filter.visibleTo.length > 0 ? filter.visibleTo[0] : ''}
                            onChange={(e, { value }) => handleVisibleTo('visibleTo', value)}
                        />
                    </Form.Field>
                </div>
            </Form>
        </div>
    );
}
