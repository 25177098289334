import React, { Component, useState, useEffect } from "react";
import {
  Form,
  Button,
  Message,
  Loader,
  Image,
  Dimmer,
  Input,
  Icon,
  Grid, 
  GridColumn, Card,
  CardDescription, CardContent
} from "semantic-ui-react";
// import SignUpFormIcon from "../../../images/svg/sign-up-form-icon.svg"
// import SignUpManImg from "../../../images/svg/signup-man-img.svg"
import { NavLink, useNavigation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signin } from "../../../actions/action_auth";
import { getInitialize } from "../../../actions/action_auth";
import { signup } from "../../../actions/action_auth";
import linkedin from "./images/linkedin.png";
import google from "./images/search.png";
import "./signup-form.css";
import Swal from "sweetalert2";
import { Tab } from "semantic-ui-react";
import { Link } from "react-router-dom";
// import ArcanaImg from "../../../images/svg/arcana-img.svg"


import Validator, { isStrongPassword } from "validator";
import InlineError from "../../../messages/InlineError";
import HireTheBest from "./HireTheBest/HireTheBest";
// import config from "../../../config";

export default function SigninForm() {
  const dispatch = useDispatch();
  const { talent_pool, cand_id, job_id, stage } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    client_name: "",
    email: "",
    password: "",
  });

  const panes = [
    {
      menuItem: "Tab 1",
      render: () => (
        <Tab.Pane>
          <div
            className="signin-form"
            style={{
              borderTop: "1px solid #00000040",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px #00000040",
              padding: "0 1rem 2rem",
            }}
          >
            {/* <Container> */}
            {/* <Grid centered columns={2}>
            <Grid.Column> */}
            <br />
            {errors.email && (
              <Message negative>
                <Message.Header>Email is not activated</Message.Header>
                <p>
                  We have sent a mail to your registered email. Please check and
                  activate
                </p>
              </Message>
            )}
            {/* <Grid container spacing={2}> */}

            {/* </Grid> */}
            <br />
            <br />
            <br />
            <div class="ui stackable two column grid">
              <div class="column">
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={handleGoogle}
                  style={{
                    textTransform: "capitalize",
                    color: "#3f3f3f",
                    background: "#fff",
                    border: "1px solid #645B5B",
                  }}
                >
                  <img
                    src={google}
                    width="24px"
                    style={{ marginRight: "10px" }}
                  />
                  Login via Google
                </Button>
              </div>

              <div class="column">
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={handleLinkedin}
                  style={{
                    textTransform: "capitalize",
                    color: "#fff",
                    background: "#0073B0",
                  }}
                >
                  <img
                    src={linkedin}
                    width="24px"
                    style={{ marginRight: "10px" }}
                  />
                  Login via Linkedin
                </Button>
              </div>
            </div>

            <Form onSubmit={onSubmit}>
              <br />
              <br />
              <br />
              <Form.Field>
                <label style={{ color: "#003458" }}>Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={data.email}
                  onChange={onChange}
                  required
                  style={{ padding: "1.2rem" }}
                />
                {/* <input 
              id="email" 
              name="email" 
              type="email" 
              required 
              pattern="^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$" 
              oninvalid="setCustomValidity('Please enter business email')" 
              onchange="try{setCustomValidity('')}catch(e){}')" />
              {/* {errors.email && <InlineError text={errors.email} />} */}
                {errors.email && <InlineError text={errors.email} />}
              </Form.Field>
              <Form.Field>
                <label style={{ color: "#003458" }}>Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  value={data.password}
                  onChange={onChange}
                  required
                  style={{ padding: "1.2rem" }}
                />
                {/* {errors.password && <InlineError text={errors.password} />} */}
                {errors.password && <InlineError text={errors.password} />}
              </Form.Field>

              <Button
                floated="right"
                type="submit"
                // loading={false}
                fluid
                style={{
                  color: "white",
                  background: "#21C8AA",
                  padding: "1rem 0",
                  // width: "90px",
                  // height: "36px",
                }}
                disabled={loading}
              >
                {loading ? (
                  <Loader size="tiny" active inline="centered" />
                ) : (
                  "GET STARTED _"
                )}
              </Button>
            </Form>
            <br />
            <br />
            <div style={{ textAlign: "center", margin: "1rem 0 0" }}>
              <NavLink to="/forgot-password">
                <h5 style={{ color: "#003458" }}>Forgot password?</h5>
              </NavLink>
            </div>
            {/* </Grid.Column>
          </Grid> */}
            {/* </Container> */}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Tab 2",
      render: () => (
        <Tab.Pane>
          <div
            className="signin-form"
            style={{
              borderTop: "1px solid #00000040",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px #00000040",
              padding: "0 1rem 2rem",
            }}
          >
            {/* <Container> */}
            {/* <Grid centered columns={2}>
            <Grid.Column> */}
            <br />
            {errors.email && (
              <Message negative>
                <Message.Header>Email is not activated</Message.Header>
                <p>
                  We have sent a mail to your registered email. Please check and
                  activate
                </p>
              </Message>
            )}
            {/* <Grid container spacing={2}> */}

            {/* </Grid> */}
            <br />
            <br />
            <br />
            <div class="ui stackable two column grid">
              <div class="column">
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={handleGoogle}
                  style={{
                    textTransform: "capitalize",
                    color: "#3f3f3f",
                    background: "#fff",
                    border: "1px solid #645B5B",
                  }}
                >
                  <img
                    src={google}
                    width="24px"
                    style={{ marginRight: "10px" }}
                  />
                  Login via Google
                </Button>
              </div>

              <div class="column">
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={handleLinkedin}
                  style={{
                    textTransform: "capitalize",
                    color: "#fff",
                    background: "#0073B0",
                  }}
                >
                  <img
                    src={linkedin}
                    width="24px"
                    style={{ marginRight: "10px" }}
                  />
                  Login via Linkedin
                </Button>
              </div>
            </div>

            <Form onSubmit={onSubmit}>
              <br />
              <br />
              <br />
              <Form.Field>
                <label style={{ color: "#003458" }}>Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={data.email}
                  onChange={onChange}
                  required
                  style={{ padding: "1.2rem" }}
                />
                {/* {errors.email && <InlineError text={errors.email} />} */}
                {errors.email && <InlineError text={errors.email} />}
              </Form.Field>
              <Form.Field>
                <label style={{ color: "#003458" }}>Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  value={data.password}
                  onChange={onChange}
                  required
                  style={{ padding: "1.2rem" }}
                />
                {/* {errors.password && <InlineError text={errors.password} />} */}
                {errors.password && <InlineError text={errors.password} />}
              </Form.Field>

              <Button
                floated="right"
                type="submit"
                // loading={false}
                fluid
                style={{
                  color: "white",
                  background: "#21C8AA",
                  padding: "1rem 0",
                  // width: "90px",
                  // height: "36px",
                }}
                disabled={loading}
              >
                {loading ? (
                  <Loader size="tiny" active inline="centered" />
                ) : (
                  "GET STARTED _"
                )}
              </Button>
            </Form>
            <br />
            <br />
            <div style={{ textAlign: "center", margin: "1rem 0 0" }}>
              <NavLink to="/forgot-password">
                <h5 style={{ color: "#003458" }}>Forgot password?</h5>
              </NavLink>
            </div>
            {/* </Grid.Column>
          </Grid> */}
            {/* </Container> */}
          </div>
        </Tab.Pane>
      ),
    },
    // { menuItem: 'Tab 3', render: () => <Tab.Pane>Tab 3 Content</Tab.Pane> },
  ];

  const Swal = require("sweetalert2");

  useEffect(() => {
    // Function to parse query parameters from the URL
    const getQueryParam = (name) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      return urlSearchParams.get(name);
    };

    // Check if the 'status' parameter is present in the URL
    const status = getQueryParam("status");
    // const urlSearchParams = new URLSearchParams(window.location.search);
    const isRegistered = getQueryParam("isRegistered");
    const isOAuth = getQueryParam("isOAuth");
    const message = getQueryParam("message");
    console.log(
      "status : ",
      status,
      "isRegistered : ",
      isRegistered,
      "message : ",
      message
    );
    if (status === "422") {
      Swal.fire({
        title: "Please enter a valid business email",
        text:
          getQueryParam("message") ||
          "Please enter a valid business email. Personal emails are not allowed",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#21C8AA",
        confirmButtonText: "OK",
        onClose: navigate("/signup"),
      });
    }
    if (status === "200" && isRegistered === "true") {
      // Show SweetAlert notification
      Swal.fire({
        title: "Registration Successful",
        text: `You have successfully registered ! ${
          isOAuth === true
            ? "Please check your email to activate your account"
            : "Your account has been activated"
        }.`,
        icon: "success",
        confirmButtonColor: "#21C8AA",
        confirmButtonText: "OK",
      });
      navigate("/signin");
    }
    if (status === "500" && isRegistered === "false") {
      // Show SweetAlert notification
      Swal.fire({
        title: "Registration Unsuccessful",
        text: "Your registration was unsuccessful",
        icon: "error",
        confirmButtonColor: "#21C8AA",
        confirmButtonText: "OK",
      });
    }

    if (status === "409") {
      // '409' indicates a user already exists, show SweetAlert
      Swal.fire({
        title: "User Already Exists",
        text:
          getQueryParam("message") ||
          "An account with this email already exists.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#21C8AA",
        confirmButtonText: "OK",
        onClose: navigate("/signup"),
      });
    }

    const token = localStorage.getItem("accessToken");
    if (token) {
      console.log("token for OAuth recieved : ", token);
      //  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch(getInitialize()).then((res) => {
        // console.log("This result is recieved from get initialize : ", res.data.response.status);
        console.log("it is happing from here ")
        if (res.data.response?.status === 500 || 401 || 403 || 404)
          localStorage.removeItem("accessToken");
        else {
          navigate("/jobs");
        }
      });
      // Swal.fire({
      //   title: 'Login Successful',
      //   text: "Successfully Logged in.",
      //   icon: 'success',
      //   confirmButtonText: 'OK'
      // });
    } else {
      console.log("Access token not present in localStorage");
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const onChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
    setCustomValidityMessage("");
    console.log("sign up data : ", data);
  };
  const [customValidityMessage, setCustomValidityMessage] = useState("");

  const validate = () => {
    const validationErrors = {};
    if (!Validator.isEmail(data.email)) {
      validationErrors.email = "Invalid email";
    }

    const emailPattern =
      /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|yahoo\.co\.in|aol\.com|live\.com|outlook\.com)[a-zA-Z0-9_-]+\.[a-zA-Z0-9-.]{2,61}$/;

    if (!emailPattern.test(data.email)) {
      validationErrors.email = "Please enter a valid business email";
    }

    if (
      !isStrongPassword(data.password, [
        { minLength: 8, minUppercase: 1, minNumbers: 1, minSymbols: 1 },
      ])
    ) {
      validationErrors.password =
        "Atleast 8 characters required, 1 Uppercase, 1 Number and 1 Symbol required";
    }
    if (!data.password) {
      validationErrors.password = "Password is required!";
    }

    setErrors(validationErrors);

    return validationErrors;
  };
  // const SERVER_URL = "http://localhost:5002"
  // const SERVER_URL = "http://165.227.4.217:5002"
  const handleGoogle = () => {
    // window.open("http://192.168.29.85:5000/api/auth/google")
    const googleSignUrl = `${process.env.REACT_APP_API_URL}/api/auth/google-register`;
    // const googleSignUrl = `${config.devwindow.location.origin}/api/auth/google`
    window.open(googleSignUrl, "_self");
  };

  const handleLinkedin = () => {
    // window.open("http://192.168.29.85:5000/api/auth/google")
    const googleSignUrl = `${process.env.REACT_APP_API_URL}/api/auth/linkedin-register`;
    // const googleSignUrl = `${config.devwindow.location.origin}/api/auth/linkedin`

    window.open(googleSignUrl, "_self");
  };

  const onSubmit = async () => {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setCustomValidityMessage("");
      setLoading(true);
      dispatch(signup(data))
        .then((res) => {
          console.log("sign up successful : ", res);
          Swal.fire({
            title: "Sign up successful !",
            text: `${res.data.message}. Please check your email for an activation link valid for 5 minutes.`,
            icon: "success",
            confirmButtonColor: "#21C8AA",
            confirmButtonText: "OK",
          });
          setLoading(false);
          navigate("/signin");
        })
        .catch((err) => {
          console.log("error in sign up", err.response.status);
          if (err.response.status === 409) {
            Swal.fire({
              title: "Error in Sign up !",
              text: err.response.data.message,
              icon: "error",
              confirmButtonColor: "#21C8AA",
              confirmButtonText: "OK",
            });
          } else if (err.response.status === 422) {
            Swal.fire({
              title: "Error in Sign up !",
              text: err.response.data.error,
              icon: "error",
              confirmButtonColor: "#21C8AA",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: "Error in Sign up !",
              text: "Some error occured while signing up",
              icon: "error",
              confirmButtonColor: "#21C8AA",
              confirmButtonText: "OK",
            });
            // Swal.fire({
            //   title: 'Sign up successful !',
            //   text: `Signup successful. Please check your email for an activation link valid for 5 minutes.`,
            //   icon: 'success',
            //   confirmButtonColor: '#21C8AA',
            //   confirmButtonText: 'OK'
            // });
          }
          console.log("error in sign up", err);
          setLoading(false);
        });
    }
  };

  console.log("errors in sign up", errors);
  // )

  // `${window.location.origin.toString()}/talent_pool/candidate/${cand_id}/bio/public` ? `${window.location.origin.toString()}/talent_pool/candidate/${cand_id}/bio/` : navigate("/jobs")
  // if(`${window.location.origin.toString()}/talent_pool/candidate/${cand_id}/bio/public`){
  //    console.log("hey brooooooo")
  // }
  // else{

  // `${window.location.origin.toString()}/talent_pool/candidate/${cand_id}/bio/public` ? `${window.location.origin.toString()}/talent_pool/candidate/${cand_id}/bio/` : navigate("/jobs")
  // if(`${window.location.origin.toString()}/talent_pool/candidate/${cand_id}/bio/public`){
  //    console.log("hey brooooooo")
  // }
  // else{

  //   console.log("hey brooooooo2")
  // }
  // if(`${window.location.origin.toString()}/talent_pool/candidate/${cand_id}/bio/public`){
  //   console.log("public link")
  //   `${window.location.origin.toString()}/talent_pool/candidate/${cand_id}/bio`
  // }
  // else{
  // console.log("else state")
  // navigate("/jobs");
  // }

  //     } catch (error) {
  //       if (error.response && error.response.data) {
  //         const responseErrors = error.response.data;
  //         if (responseErrors.error === "Email not provided!") {
  //           setErrors({ email: responseErrors.error });
  //         } else if (responseErrors.error === "This email does not exist") {
  //           setErrors({ email: responseErrors.error });
  //         } else if (responseErrors.error === "User is not activated") {
  //           setErrors({ email: responseErrors.error });
  //         } else {
  //           setErrors({ password: responseErrors.error });
  //         }
  //       }
  //     } finally {
  //       setLoading(false);
  //     }
  //   } else {
  //     setErrors(validationErrors);
  //   }
  // };

  return (
    // <div className='sign-up-component'>
    <Grid stackable relaxed container columns='equal'>
    <GridColumn width={8} style={{padding:'2.5rem 4rem 2.5rem 0rem'}} className="first-grid-column">
<HireTheBest />
    </GridColumn>
    <GridColumn width={8}>
    <div
      className="signup-form"
      style={{
        backgroundColor: "white",
        borderTop: "1px solid #00000040",
        borderRadius: "10px",
        boxShadow: "0px 4px 6px 2px #e4e4e4",
        marginTop: "6%",
        padding: "0 2rem 2rem",
      }}
    >
      <h5 className="page-title">Create your account</h5>
      <div class="ui stackable two column grid">
        <div class="column">
          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={handleGoogle}
            fluid
            style={{
              // textTransform:'capitalize',
              color: "#3f3f3f",
              background: "#fff",
              border: "1px solid #645B5B",
            }}
          >
            <img src={google} width="13px" style={{ marginRight: "5px" }} />
            Sign up with Google
          </Button>
        </div>

        <div class="column">
          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={handleLinkedin}
            style={{
              // textTransform:'capitalize',
              color: "#fff",
              background: "#0073B0",
            }}
            fluid
          >
            <img src={linkedin} width="13px" style={{ marginRight: "5px" }} />
            Signup with Linkedin
          </Button>
        </div>
      </div>
      <br />
      <div className="separator">OR</div>
      <br />
      {/* this form  */}
      <Form onSubmit={onSubmit}>
        <Form.Group widths="equal">
          {/* <Form.Input
        
      /> */}
          <Form.Field>
            {/* <label style={{ color: "#003458" }}>Email</label> */}
            <input
              fluid
              id="form-subcomponent-shorthand-input-first-name"
              // label='First name'
              placeholder="First Name*"
              type="text"
              name="first_name"
              required
              fullWidth
              value={data.first_name}
              onChange={onChange}
              style={{ padding: "1.2rem" }}
            />
            {/* {errors.email && <InlineError text={errors.email} />} */}
            {errors.first_name && <InlineError text={errors.first_name} />}
          </Form.Field>
          <Form.Field>
            {/* <label style={{ color: "#003458" }}>Email</label> */}
            <input
              fluid
              id="form-subcomponent-shorthand-input-first-name"
              // label='First name'
              placeholder="Last Name*"
              type="text"
              name="last_name"
              required
              fullWidth
              value={data.last_name}
              onChange={onChange}
              style={{ padding: "1.2rem" }}
            />
            {/* {errors.email && <InlineError text={errors.email} />} */}
            {errors.last_name && <InlineError text={errors.last_name} />}
          </Form.Field>
        </Form.Group>
        <Form.Field>
          {/* <label style={{ color: "#003458" }}>Email</label> */}
          <input
            type="text"
            name="client_name"
            // id="company"
            placeholder="Company*"
            required
            fullWidth
            value={data.client_name}
            onChange={onChange}
            style={{ padding: "1.2rem" }}
          />
          {/* {errors.email && <InlineError text={errors.email} />} */}
          {errors.company && <InlineError text={errors.company} />}
        </Form.Field>
        <Form.Field>
          {/* <label style={{ color: "#003458" }}>Email</label> */}
          {/* <input
                type="email"
                name="email"
                id="email"
                placeholder="Work Email*"
                value={data.email}
                onChange={onChange}
                required
                style={{padding: "1.2rem"}}
              /> */}

          <input
            id="email"
            name="email"
            type="email"
            required
            // pattern="^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+\.[a-zA-Z0-9-.]{2,61}$"
            onChange={onChange}
            onInvalid={() =>
              setCustomValidityMessage("Please enter a valid business email")
            }
            placeholder="Work Email*"
            value={data.email}
            style={{ padding: "1.2rem" }}
          />
          {customValidityMessage && (
            <p style={{ color: "red" }}>{customValidityMessage}</p>
          )}
          {/* {errors.email && <InlineError text={errors.email} />} */}
          {errors.email && <InlineError text={errors.email} />}
        </Form.Field>
        <Form.Field>
          <Input icon placeholder="Search...">
            <input
              type={visible ? "text" : "password"}
              name="password"
              id="password"
              placeholder="Password"
              value={data.password}
              onChange={onChange}
              style={{ padding: "1.2rem" }}
            />
            <Icon
              name={visible ? "eye slash" : "eye"}
              onClick={() => setVisible((visible) => !visible)}
              // circular
              link
            />
          </Input>
          {/* {errors.email && <InlineError text={errors.email} />} */}
          {errors.password && <InlineError text={errors.password} />}
        </Form.Field>
        <p className="small-text-before-btn">
          By creating an account you consent to Qrata's{" "}
          <span>
            <a href="https://qrata.ai/terms-service.html">Terms & Conditions</a>
          </span>{" "}
          and{" "}
          <span>
            <a href="https://qrata.ai/privacy-policy.html">Privacy Policy</a>
          </span>
        </p>

        <Button
          floated="right"
          type="submit"
          // loading={false}
          fluid
          style={{
            color: "white",
            background: "#21C8AA",
            padding: "1rem 0",
            // width: "90px",
            // height: "36px",
          }}
          disabled={loading}
        >
          {loading ? (
            <Loader size="tiny" active inline="centered" />
          ) : (
            "GET STARTED"
          )}
        </Button>
      </Form>
      <br />
      <br />
      <div class="help-text">
        <p className="text-before-link">
          Already have an account?{" "}
          <Link to="/signin" className="not-this-link">
            Log in
          </Link>
        </p>
      </div>
      {/* <div style={{textAlign: 'center', margin: '1rem 0 0'}}>
            <NavLink to="/forgot-password">
              <h5 style={{ color: "#003458" }}>Forgot password ?</h5>
            </NavLink>
          </div> */}
      {/* </Grid.Column>
          </Grid> */}
      {/* </Container> */}
    </div>
    </GridColumn>
  </Grid>

    
  );
}
