import { SET_TOTAL_APPLICANTS_COUNT, MAIN_DASHBOARD_FOR_REPORTS,CANDIDATE_REPORTS } from './../types.js'

const initialState = {
  applications_count: [],
  job_against: [],
  new_candidates: [],
  new_jobs: [],
  interview: [],
  talentpool: [],
  jobSummary: [],
  interviewSchedule: []
}

export default function career (state = initialState, action = {}){
  switch(action.type){
    case SET_TOTAL_APPLICANTS_COUNT:
      return Object.assign({}, state,
                                    { applications_count : action.data.job},
                                    {job_against: action.data.job_against},
                                    {new_candidates: action.data.new_candidates},
                                    {new_jobs: action.data.new_jobs},
                                    {interview: action.data.interview},
                                    {talentpool: action.data.talentpool},
                                    {interviewCount: action.data.interviewCount},
                                    {job_count: action.data.job_count},
                                  )
    case MAIN_DASHBOARD_FOR_REPORTS :

      return {
        ...state,
        jobSummary: action.data.jobSummary,
        interviewSchedule: action.data.interviewSchedule,
        data:action.data
      }
     case CANDIDATE_REPORTS:
       return {
        ...state,
        candidateReport:action.data
       }
    default:
        return state;
  }
}
