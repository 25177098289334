import {
  SET_ALL_TALENT,
  SET_JOB_FOR_TALENT_POOL,
  ADD_TALENTPOOL_NOW,
  SET_TALENT_FILTER,
  LOADING_API,
  UPDATE_TALENTFILTER,
  REQUEST_SUBMIT
} from "./../types";
import { getHeader } from "./../types.js";
import api from "./../api/talent_pool_api";
import api1 from "./../api/application_api";
import axios from "axios";

export function setAllTalent(data) {

  return {
    type: SET_ALL_TALENT,
    data,
  };
}

export const setPage = (data) => dispatch =>{
  return dispatch({
    type: 'SET_PAGE',
    data
  })
}

export const setFilterValue = (data) => dispatch => {
  return dispatch({
    type: 'SET_FILTER_VALUE',
    data
  })
};

export const setCustomPool = (data) => dispatch => {
  return dispatch({
    type: 'SET_CUSTOM_POOL',
    data
  })
}

export function setJobsForTalentPool(data) {
  return {
    type: SET_JOB_FOR_TALENT_POOL,
    data,
  };
}

export function AddTalentPoolNow(data) {
  console.log("action datas from addtalent pool now : ", data)
  return {
    type: ADD_TALENTPOOL_NOW,
    data,
  };
}

export function setTalentFilter(data) {
  return {
    type: SET_TALENT_FILTER,
    data,
  };
}

export function requestSubmit(data) {
  return {
    type: REQUEST_SUBMIT,
    data,
  };
}


// creating job title and job description
export const getAllTalent =
  (page, filter,datas) => async (dispatch) => {
    console.log(page,filter,datas,"fldhjskbs")
    const {
      addedDate,
      curSt,
      experience,
      locations,
      maxNotice,
      minNotice,
      qaSt,
      role,
      salary,
      searchByName,
      addedBy,
      skills,
      searchByQuery,
      tag,     
      typeofEmployment,
      isMappedCandidates,
    } = filter;
    let filterValues = {
      minExp: experience?.min,
      maxExp: experience?.max,
      minCompensation: salary?.min,
      maxCompensation: salary?.max,
      minNotice,
      maxNotice,
      location: locations,
      searchByName,
      addedBy,
      searchByQuery,
      skills,
      designation: role?.role_type,
      subCategory: role?.role_type ? [role.subCategory] : [],
      addedDate,
      vetted: curSt,
      qa: qaSt,
      tag,
      typeOfEmployment: typeofEmployment,
      isMappedCandidates:isMappedCandidates
    }
    console.log(" kjhvfs", filter)

    dispatch({
      type: LOADING_API,
    });
    const data = await api.talent_pool.getAllTalentApi(
      page,
      filterValues,
    );
    return dispatch(setAllTalent(data));
  };

  export const getAllCustomPool = (poolId) => async dispatch => {
    const data = await api.talent_pool.getAllTalentOfCustomPoolApi(poolId);
    console.log("custom pool returned", data);
    return dispatch(setAllTalent(data))
  };

export const getTalentFilter = (page, filter) => async (dispatch) => {
  const data = await api.talent_pool.getTalentFilter(page, filter);
  return dispatch(setTalentFilter(data));
};

export const getJobForTalentPool = (c_Id, client_Id) => async (dispatch) => {
  const data = await api.talent_pool.getJobForTalentPoolApi(c_Id, client_Id);
  return dispatch(setJobsForTalentPool(data));
};

export const addTalentToTalentPool =
  (data, userData, isResumeAutoFill) => (dispatch) => {
    let resume = data.file;
console.log("dat from cand", data)

    return new Promise((resolve, reject) => {
      let updatedData;
      if (isResumeAutoFill) {

        data.data.veriStat = {
          proAddBy: userData.email,
          pod_group: userData.pod_group,
        };
        updatedData = {
            ...data,
            veriStat: {
              proAddBy: userData.recruiter_email,
              // pod_group: userData.pod_group,
            },
          isResumeAutoFill: true,
        };
        // return 0;
        return axios
          .post("/api/talentpool/addtalent", updatedData, {headers : getHeader()})
          .then(
            (data) => {
              let talentobj = {
                first_name: data.data.firstName,
                last_name: data.data.lastName,
                email: data.data.email,
                mobile: data.data.contactNumber,
                currentDesignation: data.data.currentDesignation,
                location: data.data.currentLocation,
                _id: data.data.email,
                talent_id: data.data._id,
                experience: data.data.experience,
                // skills: data.data.skills,
                currentSalary: data.data.currentSalary,
                expectedCTC: data.data.preferredSalary,
                currentCompany: data.data.currentCompany,
                searchTag: data.data.searchTag,
                noticePeriod: data.data.noticePeriod,
                lastWorkingDay: data.data.lastWorkingDay || '',
                createdAt : data.data.createdAt,
                lastUpdated : data.data.updatedAt
              };
              dispatch(AddTalentPoolNow(talentobj));
              console.log("resume null", resume);
              if (resume !== "" || resume !== null || !resume) {
                api1.application.resumeUploadApi(resume, data.data._id);
              }
              resolve(talentobj);
            },
            (error) => {
              reject(error);
            }
          );
      }
      // return axios.post('/api/talentpool/addtalent', data, headers)
      // .then(
      //   (data) => {
      //     console.log('RESULT', data)
      //     let talentobj = {
      //       first_name: data.data.firstName,
      //       last_name: data.data.lastName,
      //       email: data.data.email,
      //       mobile: data.data.contactNumber,
      //       currentDesignation: data.data.currentDesignation,
      //       location: data.data.currentLocation,
      //       _id: data.data.email,
      //       talent_id: data.data._id,
      //       skills: data.data.skills,
      //       searchTag: data.data.searchTag
      //     }
      //     dispatch(AddTalentPoolNow(talentobj))
      //     if(resume !== '' || resume !== null ){
      //       api1.application.resumeUploadApi(resume, data.data._id);
      //     }
      //     resolve(talentobj)
      // }, error => {
      //   reject(error)
      // })
    });

    // return api.talent_pool.addTalentToTalentPoolApi(fd).then(data => {
    //   console.log('action res - ', data)
    //   // return dispatch(setTalentToTalentPool(data))
    // })
  };

export const getSavedSearchAndCustomPool = () => async dispatch => {
  const data = await api.talent_pool.getSavedSearchAndCustomPoolApi();
  return dispatch({
    type: 'GET_SAVED_SEARCH',
    data
  })
};

export const createSavedSearch = (newSearch) => async dispatch => {
  const data = await api.talent_pool.createSavedSearchApi(newSearch);
  return dispatch({
    type: 'CREATE_SAVED_SEARCH',
    data
  })
};

export const editSavedSearch = (searchId, modifiedSearch) => async dispatch => {
  const data = await api.talent_pool.editSavedSearchApi(searchId, modifiedSearch);
  return dispatch({
    type: 'EDIT_SAVED_SEARCH',
    data
  })
};

export const deleteSavedSearchAndCustomPool = (searchId) => async dispatch => {
  const data = await api.talent_pool.deleteSavedSearchAndCustomPoolApi(searchId);
  return dispatch({
    type: 'DELETE_SAVED_SEARCH_AND_CUSTOM_SEARCH',
    data: {
      _id: searchId
    }
  })
};

export const getCustomPool = () => async dispatch => {
  const data = await api.talent_pool.getCustomPoolApi();
  return dispatch({
    type: 'GET_CUSTOM_POOL',
    data
  })
};

export const createCustomPool = (newSearch) => async dispatch => {
  const data = await api.talent_pool.createCustomPoolApi(newSearch);
  return dispatch({
    type: 'CREATE_CUSTOM_POOL',
    data
  })
};

export const editCustomPool = (searchId, modifiedSearch) => async dispatch => {
  const data = await api.talent_pool.editSavedSearchApi(searchId, modifiedSearch);
  return dispatch({
    type: 'EDIT_CUSTOM_POOL',
    data
  })
};

export const removeIdFromCustomPool = (idsArray) => async dispatch => {
  return dispatch({
    type: 'REMOVE_IDS_FROM_CUSTOM_POOL',
    data: idsArray
  })
}

// export const requestSubmitItems = (jobId, item, talentId, body) => async dispatch => {
//   const data = await api.talent_pool.requestSubmitApi(jobId, item, talentId, body);
//   return dispatch({
//     type: 'REQUEST_SUBMIT',
//     data
//   })
// };


// export const addDocuments =(data,talent_id)=>{

//   // console.log("Data --",data,"+Candiate ID",talent_id)

//   // let docs = data.file;
//   // const fd = new FormData();
//   //  fd.append('title',data.title);
//   //  fd.append('link',data.link);
//   //  fd.append('description',data.description);
//   //  fd.append('file',data.file)
//   api1.application.commanDocumetsUploadApi(data,talent_id).then(data => {
//     return dispatch(AddTalentPoolNow(data))
//     // return dispatch({
//     //   type: EDITED_EDUCATION,
//     //   data,
//     // });
//   });

// }

export const sendMailToTalentsNow =
  (emails, mailContent, companyid) => (dispatch) => {
    return api.talent_pool
      .sendMailToTalentsNowApi(emails, mailContent, companyid)
      .then((data) => {
        return data;
      });
  };

// export const setUpdateTalentFilter = (data) => {
//   console.log("updatedfiter-action", data);
//   return {
//     type: "UPDATE_TALENTFILTER",
//     data,
//   };
// };

// export const getAllTalented =
//   (page, filter, updatedfilter) => async (dispatch) => {
//     dispatch({
//       type: LOADING_API,
//     });
//     const data = await api.talent_pool.getAllTalentApi(
//       page,
//       filter,
//       updatedfilter
//     );
//     console.log("lopez-", updatedfilter);
//     return dispatch(setUpdateTalentFilter(data));
//   };

// export const updateTalentFilter =
//   (page, filter, updatedfilter) => async (dispatch) => {
//     const data = await api.talent_pool.getTalentFilter(
//       page,
//       filter,
//       updatedfilter
//     );
//     console.log("------------updateTalentFilter-------------", data);
//     return dispatch(setUpdateTalentFilter(data));
//   };
