import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Button, Icon, Image } from "semantic-ui-react";

import People from "../../images/svg/people.svg";
import CeoIcon from "../../pages/image.png";
import AddTalent from "../Modal/addTalent";
import { useModal } from "../../customHooks";
import DisplayPortal from "../../DisplayPortal";
import AddTalentModal from "../Modal/AddTalentModal";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { config } from "../../config";

export default function TalentPoolHeader({
  isPublic,
  poolAndSearches,
  clientActiveIndex,
  setClientActiveIndex,
  unlockRemaining
}) {
  
  const navigate = useNavigate();

  const { totalCandidates } = useSelector((state) => state.talent_pool);

  const userRole = useSelector((state) => state.user.user?.role);

  const [isOpenAddTalent, setIsOpenAddTalent] = useState(false);

  const displayMessage = useModal();

  const handleTabChange = (index) => {
    if(!userRole) return navigate('/signin');

    setClientActiveIndex(index);
  }

  console.log("user role: " + userRole);

  return (
    <>
      <Container fluid>
        <div
          className="talent-pool-header"
        >
          {(userRole === "Admin" || userRole === "Recruiter") && (
            <div className="talent-pool-header-tab" style={{ display: "flex" }}>
              <NavLink to={`/talent-pool`}>         
              <h3
                className={`header-box ${
                  !poolAndSearches ? "header-active" : ""
                }`}
                style={{
                  letterSpacing: "0.5px",
                  fontWeight: "400",
                }}
              >
                Talent Pool
                {/* <span style={{ fontSize: "1rem", fontWeight: "normal" }}>
              (All {totalCandidates})
            </span> */}
              </h3>
              </NavLink>
              <NavLink to={`/pool-searches`} target="_blank">
                <h3
                  className={`header-box ${
                    poolAndSearches ? "header-active" : ""
                  }`}
                  style={{
                    letterSpacing: "0.5px",
                    fontWeight: "400",
                  }}
                >
                  Pools & Searches <Icon name="share square" size="small" />
                </h3>
              </NavLink>
            </div>
          )}
          {(userRole === "client" || !userRole) && (
            <div className="talent-pool-header-tab">
              <h3
                className={`header-box ${
                  clientActiveIndex === 0 ? "header-active" : ""
                }`}
                style={{
                  letterSpacing: "0.5px",
                  fontWeight: "400",
                }}
                onClick={() => handleTabChange(0)}
              >
                My Pools
                {/* <span style={{ fontSize: "1rem", fontWeight: "normal" }}>
              (All {totalCandidates})
            </span> */}
              </h3>
              <h3
                className={`header-box ${
                  clientActiveIndex === 1 ? "header-active" : ""
                }`}
                style={{
                  letterSpacing: "0.5px",
                  fontWeight: "400",
                }}
                onClick={() => handleTabChange(1)}
              >
                Unlocked Profiles
                {/* <span style={{ fontSize: "1rem", fontWeight: "normal" }}>
              (All {totalCandidates})
            </span> */}
              </h3>
            </div>
          )}
          {(userRole === "client" || !userRole) && (
            <div className="talent-pool-header-banner">
              <div className="banner-box call-banner">
                <Link to={config.calenderMeetingUrl} target="_blank">
                <img src={CeoIcon} alt="CEO of QRATA" />
                <span style={{color: '#003458'}}>Let's get on a call</span>
                </Link>
              </div>
              {
                (userRole && unlockRemaining) &&
              <div className="banner-box unlock-banner">
                <span> Unlocks Remaining: {unlockRemaining} </span>
                <Icon name={unlockRemaining > 0 ? "unlock" : "lock"} />
              </div>
              }
            </div>
          )}
          {!isPublic && !poolAndSearches && userRole !== "client" && (
            <Button
              basic
              color="teal"
              onClick={() => setIsOpenAddTalent(!isOpenAddTalent)}
              style={{ marginRight: "1.5rem" }}
            >
              <Icon>
                <Image src={People} />
              </Icon>
              Add A New Talent
            </Button>
          )}
        </div>
      </Container>
      {isPublic ||
        (userRole !== "client" && (
          <AddTalentModal
            open={isOpenAddTalent}
            close={() => setIsOpenAddTalent(false)}
            displayPortal={displayMessage.handleMessageAndStatus}
            // pod_group={userData.pod_group}
          />
        ))}
      <DisplayPortal
        open={displayMessage.open}
        onClose={displayMessage.handleChange}
        message={displayMessage.messagesAndStatus.message}
        isSuccess={displayMessage.messagesAndStatus.isSuccess}
      />
    </>
  );
}
