import { Button, Icon, Input, Segment, Header, Loader, Label } from 'semantic-ui-react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import star from './start.svg'
import { Link } from 'react-router-dom';
// import './custom.css'

const Password = ({setStep, setLoading ,handleDataChange, data, loading, onSubmit, errors}) => {
    const [visible, setVisible] = useState(false);
    const [isResetSent, setIsResetSent] = useState(false); 
    const [resetLoading, setResetLoading] = useState(false)
    const [magicLinkLoading, setMagicLinkLoading] = useState(false);
    const [magicLinkSent, setMagicLinkSent] = useState(false); // Magic link sent state
    const [magicLinkMessage, setMagicLinkMessage] = useState(""); // Message to display after magic link sent

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const buttonFontSize = screenWidth < 350 ? '0.8rem' : '1rem';
    const headerFontSize = screenWidth < 350 ? '0.8rem' : '24px';

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    console.log(headerFontSize, 'buttonFontSize')

    const handleResetPassword = async () => {
        setResetLoading(true)
        try {
            const response = await axios.post('/api/auth/forgotpassword', { email: data.email });
            if (response.status === 200) {
                setIsResetSent(true); 
            }
            console.log('Reset password response:', response.data);
        } catch (error) {
            console.error('Error sending reset password email:', error);
        } finally {
            setResetLoading(false);
        }
    };

    const handleMagicLinkRequest = async () => {
        setMagicLinkLoading(true);
        try {
            const response = await axios.post('/api/auth/request-magic-link', { email: data.email });
            if (response.status === 200) {
                setMagicLinkSent(true);
                setMagicLinkMessage(response.data.message);
            }
            console.log(response.data, 'magic link response');
        } catch (error) {
            console.log(error.response?.data?.message || 'Error sending magic link. Please try again.');
            setMagicLinkSent(false);
        } finally {
            setMagicLinkLoading(false);
        }
    };

    const handleResetChange = () => {
        setIsResetSent(false)
    }

    const openGmail = () => {
        window.open('https://mail.google.com', '_blank');
    }

    return (
        <div textAlign="center">
        {isResetSent ? (
            <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', marginTop: '1rem' }}>
                    <Icon
                        name="arrow left"
                        onClick={handleResetChange}
                        style={{ cursor: 'pointer', color: '#3D3737' }}
                    />
                    <p style={{ color: '#3D3737', fontWeight: 'bold', marginTop: "0", fontSize: screenWidth ? '1.5rem' : '0.7rem' }}>Reset your password</p>
                </div>
                <p style={{ margin: '0.5rem 0 1.5rem', color: '#3D3737', textAlign: 'center', fontSize: buttonFontSize }}>
                    Click the link on your email to reset your password.
                </p>     

                <Button
                    style={{
                        width: '100%',
                        background: "#21C8AA",
                        color: 'white',
                        margin: '1rem 0 0',
                        padding: '1rem 0',
                        textTransform: 'none',
                        borderRadius: '8px',
                        fontWeight: '600',
                        // fontSize: '1rem',
                        fontSize: buttonFontSize,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={openGmail}
                >
                    OPEN YOUR EMAIL TO RESET PASSWORD
                </Button>      
            </>
        ) : magicLinkSent ? (
            <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', marginTop: '1rem' }}>
                    <Icon
                        name="arrow left"
                        onClick={() => setMagicLinkSent(false)}
                        style={{ cursor: 'pointer', color: '#3D3737' }}
                    />
                    <Header as="h2" style={{ color: '#3D3737', fontWeight: 'bold', marginTop: "0" }}>Magic Link Sent</Header>
                </div>
                <p style={{ margin: '0.5rem 0 1.5rem', color: '#3D3737', textAlign: 'center', fontSize: buttonFontSize }}>
                    {magicLinkMessage}
                </p>

                <Button
                    style={{
                        width: '100%',
                        background: "#21C8AA",
                        color: 'white',
                        margin: '1rem 0 0',
                        padding: '1rem 0',
                        textTransform: 'none',
                        borderRadius: '8px',
                        fontWeight: '600',
                        // fontSize: '1rem',
                        fontSize: buttonFontSize,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={openGmail}
                >
                    OPEN YOUR EMAIL TO LOGIN WITH MAGIC LINK
                </Button>
            </>
        ) : (
            <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', marginTop: '1rem' }}>
                    <Icon
                        name="arrow left"
                        onClick={() => setStep(0)}
                        style={{ cursor: 'pointer', color: '#3D3737' }}
                    />
                    <Header as="h2" style={{ color: '#3D3737', fontWeight: 'bold', marginTop: "0" }}>Log In to Qrata App</Header>
                </div>
                <p style={{ margin: '0.5rem 0 1.5rem', color: '#3D3737', textAlign: 'center', fontSize: buttonFontSize }}>
                    Please enter your password to log in
                </p>
                <div>
                    <Input
                        icon
                        placeholder="Password (8+ characters)*"
                        type={visible ? 'text' : 'password'}
                        name="password"
                        value={data.password}
                        onChange={handleDataChange}
                        style={{ background: 'transparent', borderRadius: '8px', width: '100%' }}
                    >
                        <input style={{ padding: '1rem', width: '100%', fontSize: '1.2rem' }} onKeyDown={handleKeyDown} />
                        <Icon
                            name={visible ? 'eye' : 'eye slash'}
                            onClick={() => setVisible((visible) => !visible)}
                            link
                        />
                    </Input>
                    {errors.password && <Label pointing color="red">{errors.password}</Label>}
                </div>

                <Button
                    // className="button"
                    onClick={onSubmit}
                    disabled={loading}
                    style={{
                        width: '100%',
                        background: "#21C8AA",
                        color: 'white',
                        margin: '1rem 0 0',
                        padding: '1.1rem 0',
                        borderRadius: '8px',
                        fontWeight: '600',
                        // fontSize: '1.2rem',
                        fontSize: buttonFontSize,
                    }}
                >
                    {/* {loading ? <Loader size="small" active inline="centered" style={{ color: 'white' }} /> : 'Login'} */}
                    {loading ? (
                    <div className="custom-loader"></div>
                    ) : (
                    'Login'
                    )}
                </Button>

                <Button
                    // className="button"
                    style={{
                        width: '100%',
                        background: "transparent",
                        border: "2px solid #21C8AA",
                        color: '#21C8AA',
                        margin: '1rem 0 0',
                        padding: magicLinkLoading ? '0.8rem 0' : '0.4rem 0',
                        textTransform: 'none',
                        borderRadius: '8px',
                        fontWeight: '600',
                        // fontSize: '1rem',
                        fontSize: buttonFontSize,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={handleMagicLinkRequest}
                >

                    {magicLinkLoading ? (
                    <div className="custom-loader"></div>
                    ) : (
                    <>
                        <img src={star} alt="star" style={{ marginRight: '6px' }} />
                        I forgot my password, give me a magic link!
                    </>
                    )}
                </Button>

                <p id="forgetPassword" onClick={handleResetPassword} style={{ marginTop: '0.8rem', cursor: 'pointer', color: "#3D3737", textAlign: 'center', fontSize: '1.1rem' }}>
                    
                    {resetLoading ? (
                    <div className="custom-loader"></div>
                    ) : (
                        <u>Reset my password!</u>
                    )}
                </p>
            </>
        )}
    </div>
    );
};

export default Password;
