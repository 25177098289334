import {
  SET_CURRENT_JOB,
  SET_ALL_JOBS,
  SET_FILTER_JOBS,
  SET_CURRENT_JOB_APPLICATIONS,
  SET_FILTER_JOBS_VALUES,
  SET_JOB_FILTER_DROPDOWN,
  SET_MOVED_CANDIDATES_STAGE,
  SET_CURRENT_JOB_TO_EMPTY,
  SET_JOB_CLOSED,
  SET_JOB_OPENED,
  SET_SAVED_QUESTIONS,
  UPDATE_JOB,
  DELETE_JOB,
  Filter_LOCAL_JOB_Filter,
  DELETE_CANDIDATE_DOCUMENT,
  ADDTEMPLATE_JOB,
  UPDATETEMPLATE_JOB,
  SET_APPLICATIONS,
  SET_JOB_APPLICATIONS
} from "./../types";
import axios from "axios";

import api from "./../api/job_api";
import api1 from "./../api/application_api";
import { submit } from "./action_company";
import { setApplications } from "./action_application";

export function setCurrentJob(data) {
  return {
    type: SET_CURRENT_JOB,
    data,
  };
}

export function updateJob(data) {
  return {
    type: UPDATE_JOB,
    data,
  };
}

export function setCurrentJobTEmpty(data) {
  return {
    type: SET_CURRENT_JOB_TO_EMPTY,
  };
}

export function setAllJobs(data) {
  return {
    type: SET_ALL_JOBS,
    data,
  };
}

export function setFilterJobs(data) {
  return {
    type: SET_FILTER_JOBS,
    data,
  };
}

export function setFilterDropdown(data) {
  return {
    type: SET_JOB_FILTER_DROPDOWN,
    data,
  };
}

export function setFilterJobsValues(data) {
  return {
    type: SET_FILTER_JOBS_VALUES,
    data,
  };
}

export function setCurrentJobApplications(data, stage) {
  const newCounts = {
    all: 0,
    prospect: 0,
    applied: 0,
    added: 0,
    curated: 0,
    shortlisted: 0,
    interviewed: 0,
    offered: 0,
    hired: 0,
    rejected: 0,
  };
  // optimized code

  data?.forEach((application) => {
    const isRecruterNamePresent = application?.recruiterDetail?.recruiter_name;
    const isRecruterEmailPresent =
      application?.recruiterDetail?.recruiter_email;
    // console.log(isRecruterNamePresent, isRecruterEmailPresent,'recruiterDetail')
    if (
      application?.stages?.applied &&
      !application?.stages?.curated &&
      !application?.stages?.shortlisted &&
      !application?.stages?.interviewed &&
      !application?.stages?.offered &&
      !application?.stages?.hired &&
      !application?.stages?.rejected
    ) {
      newCounts.prospect++;
    }
    if (
      application?.stages?.applied &&
      !application?.stages?.curated &&
      !application?.stages?.shortlisted &&
      !application?.stages?.interviewed &&
      !application?.stages?.offered &&
      !application?.stages?.hired &&
      !application?.stages?.rejected
    ) {
      newCounts.all++;
    }
    if (
      !isRecruterNamePresent &&
      !isRecruterEmailPresent &&
      application?.stages?.applied &&
      !application?.stages?.curated &&
      !application?.stages?.shortlisted &&
      !application?.stages?.interviewed &&
      !application?.stages?.offered &&
      !application?.stages?.hired &&
      !application?.stages?.rejected
    ) {
      newCounts.applied++;
    } else if (
      isRecruterNamePresent &&
      isRecruterEmailPresent &&
      application?.stages?.applied &&
      !application?.stages?.curated &&
      !application?.stages?.shortlisted &&
      !application?.stages?.interviewed &&
      !application?.stages?.offered &&
      !application?.stages?.hired &&
      !application?.stages?.rejected
    ) {
      newCounts.added++;
    } else if (
      application?.stages?.curated &&
      !application?.stages?.shortlisted &&
      !application?.stages?.interviewed &&
      !application?.stages?.offered &&
      !application?.stages?.hired &&
      !application?.stages?.rejected
    ) {
      newCounts.curated++;
    } else if (
      application?.stages?.shortlisted &&
      !application?.stages?.interviewed &&
      !application?.stages?.offered &&
      !application?.stages?.hired &&
      !application?.stages?.rejected
    ) {
      newCounts.shortlisted++;
    } else if (
      application?.stages?.interviewed &&
      !application?.stages?.offered &&
      !application?.stages?.hired &&
      !application?.stages?.rejected
    ) {
      newCounts.interviewed++;
    } else if (
      application?.stages?.offered &&
      !application?.stages?.hired &&
      !application?.stages?.rejected
    ) {
      newCounts.offered++;
    } else if (application?.stages?.hired) {
      newCounts.hired++;
    } else if (application?.stages?.rejected) {
      newCounts.rejected++;
    }
  });

  const newData = {
    data: data,
    stage: stage,
    stageCounts: newCounts,
  };
  return {
    type: SET_CURRENT_JOB_APPLICATIONS,
    newData,
  };
}

export function setMovedCandidatesStage(data) {
  return {
    type: SET_MOVED_CANDIDATES_STAGE,
    data,
  };
}

export function setJobClosed(data) {
  return {
    type: SET_JOB_CLOSED,
    data,
  };
}

export function setJobOpened(data) {
  return {
    type: SET_JOB_OPENED,
    data,
  };
}

export function setSavedQuestions(data) {
  return {
    type: SET_SAVED_QUESTIONS,
    data,
  };
}

export function deleteCandidateDocument(data) {
  return {
    type: DELETE_CANDIDATE_DOCUMENT,
    data,
  };
}

// expty current job
export const emptyCurrentJob = () => (dispatch) => {
  return dispatch(setCurrentJobTEmpty());
};

// creating job title and job description
export const createJob = (data) => (dispatch) => {
  return api.job.createJobApi(data).then((data) => {
    return dispatch(setCurrentJob(data));
  });
};

// updating th job posting Details
export const updateCurrentJob = (data) => (dispatch) => {
  return api.job.UpdateCurrentJobApi(data).then((data) => {
    return dispatch(setCurrentJob(data));
  });
};
// publishing current job now
export const publishCurrentJob = (data) => (dispatch) => {
  return api.job.PublishCurrentJobApi(data).then((data) => {
    return dispatch(setCurrentJobTEmpty(data));
  });
};

// Get all jobs
export const getAllJobs = (data, cancelToken) => (dispatch) => {
  return api.job.GetAllJobsApi(data, cancelToken).then((data) => {
    return dispatch(setAllJobs(data));
  });
};

//Add Scorecard template to job

export const AddTemplate = (id, data) => (dispatch) => {
  return api.job.addTemplate(id, data).then((data) => {
    return dispatch({
      type: ADDTEMPLATE_JOB,
      data,
    });
  });
};

//Update Scorecrad template of job's candidate
export const UpdateTemplate = (id, job_id, data) => (dispatch) => {
  return api.job.updateTemplate(id, job_id, data).then((data) => {
    return dispatch({
      type: UPDATETEMPLATE_JOB,
      Data: data,
    });
  });
};

// get job by id
export const getJobById = (id) => (dispatch) => {
  return api.job.getJobByIdApi(id).then((data) => {
    return dispatch(setCurrentJob(data));
  });
};
// get filter values
export const getFilterJobsValues = (id) => (dispatch) => {
  return api.job.GetAllJobsApi(id).then((data) => {
    return dispatch(setFilterJobsValues(data));
  });
};

// edting a job i.e job title and jd
export const editJobByIdStart = (data, id) => (dispatch) => {
  return api.job.editJobByIdStart(data, id).then((data) => {
    return dispatch(setCurrentJob(data));
  });
};

// Editing a jobs
export const editJobById = (id) => (dispatch) => {
  return api.job.editJobByIdApi(id).then((data) => {
    return dispatch(setCurrentJob(data));
  });
};
//filterjobs
export const filterByJobLocally = (filter) => (dispatch) => {
  return dispatch({
    type: Filter_LOCAL_JOB_Filter,
    data: filter,
  });
};

// export const filterNowSubmit = (data, id, {role, user_id}) => dispatch => {

export const filterNowSubmit = (page, data) => (dispatch) => {
  dispatch({
    type: "SET_LOADING",
    data: true,
  });
  console.log("", page, "data : ", data);
  let newObj = {
    active: data?.active,
    close: data?.close,
    pending:data?.pending,
    job_title: [],
    functional_area: [],
    employment_type: [],
    job_location: [],
    client_name: [],
    subscription: [],
    designation: [],
  };
  // condition giving here only CHECK IT
  if (data?.designation && data?.designation.length > 0) {
    let newOption = data.designation.map((option) => option.value);
    newObj = { ...newObj, designation: newOption };
  }
  if (data?.functional_area?.length > 0)
    newObj = { ...newObj, functional_area: data?.functional_area };
  if (data?.employment_type && data?.employment_type.length > 0)
    newObj = { ...newObj, employment_type: data.employment_type };
  if (data?.subscription && data?.subscription.length > 0)
    newObj = { ...newObj, subscription: data.subscription };
  if (data?.location && data?.location.length > 0) {
    let newLocation = data?.location.map((loc) => loc.value);
    newObj = { ...newObj, job_location: newLocation };
  }
  if (data?.client && data?.client.length > 0) {
    let newClient = data?.client.map((option) => option.value);
    newObj = { ...newObj, client_name: newClient };
  }
  if (data?.subscription && data?.subscription.length > 0) {
    let newSubscription = data?.subscription.map((option) => option.value);
    newObj = { ...newObj, subscription: newSubscription };
  }
  // return api.job.filterJobApi(newObj, { role, user_id}).then(data => {
  api.job.filterJobApi(page, newObj).then((data) => {
    console.log("data from idhar se : ");
    return dispatch(setFilterJobs(data));
    // return dispatch(setFilterJobs(prevData=> [...prevData, data]));
  });
};

export const getJobApplications = (id, stage) => (dispatch) => {
  const stage1 = {
    applied: true,
    curated: false,
    shortlisted: false,
    interviewed: false,
    offered: false,
    hired: false,
    rejected: false,
  };

  let data = Object.assign({}, stage1, { _id: id });
  switch (stage) {
    case "applied":
      return api.job
        .getJobApplicationsApi(
          id,
          Object.assign(
            {},
            stage1,
            { applied: true }
            // { filter_content_val: filter_content_val }
          )
        )
        .then((data) => {
          return dispatch(setCurrentJobApplications(data, stage));
        });
      break;
    case "curated":
      return api.job
        .getJobApplicationsApi(
          id,
          Object.assign({}, stage1, { applied: true, curated: true })
        )
        .then((data) => {
          return dispatch(setCurrentJobApplications(data, stage));
        });
      break;
    case "shortlisted":
      return api.job
        .getJobApplicationsApi(
          id,
          Object.assign({}, stage1, {
            applied: true,
            curated: true,
            shortlisted: true,
          })
        )
        .then((data) => {
          return dispatch(setCurrentJobApplications(data, stage));
        });
      break;
    case "interviewed":
      return api.job
        .getJobApplicationsApi(
          id,
          Object.assign({}, stage1, {
            applied: true,
            curated: true,
            shortlisted: true,
            interviewed: true,
          })
        )
        .then((data) => {
          return dispatch(setCurrentJobApplications(data, stage));
        });
      break;
    case "offered":
      return api.job
        .getJobApplicationsApi(
          id,
          Object.assign({}, stage1, {
            applied: true,
            curated: true,
            shortlisted: true,
            interviewed: true,
            offered: true,
          })
        )
        .then((data) => {
          return dispatch(setCurrentJobApplications(data, stage));
        });
      break;
    case "hired":
      return api.job
        .getJobApplicationsApi(
          id,
          Object.assign({}, stage1, {
            applied: true,
            curated: true,
            shortlisted: true,
            interviewed: true,
            offered: true,
            hired: true,
          })
        )
        .then((data) => {
          return dispatch(setCurrentJobApplications(data, stage));
        });
      break;
    case "rejected":
      return api.job
        .getJobApplicationsApi(
          id,
          Object.assign({}, stage1, { rejected: true })
        )
        .then((data) => {
          return dispatch(setCurrentJobApplications(data, stage));
        });
      break;
    default:
      break;
  }
};

export const moveCandidate =
  (job_id, app_id, stage, onSuccess) => (dispatch) => {
    let newStage = {};
    if (stage === "applied") {
      newStage.applied = true;
    } else if (stage === "curated") {
      newStage.curated = true;
    } else if (stage === "shortlisted") {
      newStage.shortlisted = true;
    } else if (stage === "interviewed") {
      newStage.interviewed = true;
    } else if (stage === "offered") {
      newStage.offered = true;
    } else if (stage === "hired") {
      newStage.hired = true;
    }

    return api.job
      .moveCandidateStageApi(job_id, app_id, newStage)
      .then((data) => {
        return dispatch(setMovedCandidatesStage(data));
      })
      .finally(() => {
        onSuccess();
      });
  };

export const rejectNow = (job_id, data) => (dispatch) => {
  console.log(job_id, data, "dskfhjdsf sd");
  return api.job
    .rejectCandApi(job_id, data.reject_app_id, data)
    .then((data) => {
      return dispatch(setMovedCandidatesStage(data));
    });
};
export const candidateDeleteFromJob = (applicationId, jobId) => (dispatch) => {
  return api.job
    .candidateDeleteFromJobApi(applicationId, jobId)
    .then((data) => dispatch({
      type: "DELETE_APPLICATION_FROM_JOB",
      data
    }));
};

export const documentDeleteFromJob =
  (documentId, jobId, applicationId) => (dispatch) => {
    api.job
      .documentDeleteFromJobApi(documentId, jobId, applicationId)
      .then((data) => data);
    api.job
      .documentDeleteFromJobApi(documentId, jobId, applicationId)
      .then((res) => {
        return dispatch(
          deleteCandidateDocument({
            type: DELETE_CANDIDATE_DOCUMENT,
            data: res,
          })
        );
      });
  };

export const deleteJob = (id) => (dispatch) => {
  return api.job.DeleteJobApi(id).then((data) => {
    return dispatch({
      type: DELETE_JOB,
      jobId: id,
    });
  });
};
export const shouldJobBeClosed = (id) => (dispatch) => {
  return api.job.shouldJobBeClosedApi(id).then((data) => {
    return dispatch(setJobClosed(data));
  });
};

export const shouldJobBeOpened = (id) => (dispatch) => {
  return api.job.JobOpenApi(id).then((data) => {
    return dispatch(setJobOpened(data));
  });
};

export const addQuestionToJob = (id, question) => (dispatch) => {
  return api.job.addQuestionToJobApi(id, question).then((data) => {
    return dispatch(setSavedQuestions(data));
  });
};

export const deleteQuestionNow = (id, question_id) => (dispatch) => {
  // console.log('------', id, question_id)
  return api.job.deleteQuestionNowApi(id, question_id).then((data) => {
    // console.log('action res', data)
    return dispatch(setSavedQuestions(data));
  });
};

// export const addTalentToTalentPoolFromJob =
//   (data, jobId, resume) => async (dispatch) => {
//     // let updatedData ={
//     //   data:{
//     //     ...data.data,
//     //     veriStat:{proAddBy:data.recruiterDetail.recruiter_email }
//     //   },
//     //   recruiterDetail: data.recruiterDetail,
//     //   job_id: data.job_id,
//     //   companyId: data.companyId,
//     //   file: data.file,
//     // }
//     // console.log("vk+",data)

//     return new Promise((resolve, reject) => {
//       return api.job
//         .addDataToTalentPool({ data: data, job_id: jobId })
//         .then((data) => {
//           console.log("check data : ", data)
//           console.log("check data.inserted  : ", data.inserted)
//           // console.log("data.user._id------------------------",data.user)
//           if (jobId) {
//             dispatch({
//               type: UPDATE_JOB,
//               data: data.inserted,
//             });
//             // dispatch({
//             //   type: SET_JOB_APPLICATIONS,
//             //   data: data.inserted.applications
//             // })
//             const tempData = data.inserted.applications[data.inserted.applications.length - 1]
//             const sendData = {...tempData, userDetail: [data.userDetail] }
//             console.log("check here sendData : ", sendData)
//             dispatch({
//               type: SET_JOB_APPLICATIONS,
//               data: sendData
//             });
//             // dispatch(setApplications(data.inserted.applications))
//             // setCurrentJobApplications(data.inserted.applications, "applied")
//           }

//           if (resume !== "" || resume !== null) {
//             api1.application.resumeUploadApi(resume, data.user);
//           }
//           resolve(data);
//           // dispatch(updateJob(data.inserted))
//         })
//         .catch((error) => {
//           console.log("catch", error.response);
//           reject(error);
//           return error;
//         });
//     });
//   };

export const addTalentToTalentPoolFromJob =
  (data, isResumeAutoFill) => async (dispatch) => {
    let resume = data.file;
    let jobId = data.job_id;
    data.data.veriStat = {
      proAddBy: data.recruiterDetail.recruiter_email,
      pod_group: data.recruiterDetail.pod_group,
    };
    // let updatedData ={
    //   data:{
    //     ...data.data,
    //     veriStat:{proAddBy:data.recruiterDetail.recruiter_email }
    //   },
    //   recruiterDetail: data.recruiterDetail,
    //   job_id: data.job_id,
    //   companyId: data.companyId,
    //   file: data.file,
    // }
    // console.log("vk+",data)
console.log(data,"sdfdksfnkjads", data.data)
    return new Promise((resolve, reject) => {
      return api.job
        .addDataToTalentPool(data)
        .then((data) => {
          console.log("check data : ", data)
          console.log("check data.inserted  : ", data.inserted)
          // console.log("data.user._id------------------------",data.user)
          if (jobId) {
            dispatch({
              type: UPDATE_JOB,
              data: data.inserted,
            });
            // dispatch({
            //   type: SET_JOB_APPLICATIONS,
            //   data: data.inserted.applications
            // })
            const tempData = data.inserted.applications[data.inserted.applications.length - 1]
            const sendData = {...tempData, userDetail: [data.userDetail] }
            console.log("check here sendData : ", sendData);
            if (resume !== "" || resume !== null) {
              api1.application.resumeUploadApi(resume, data.user);
            }
            dispatch({
              type: SET_JOB_APPLICATIONS,
              data: sendData
            });
            // dispatch(setApplications(data.inserted.applications))
            // setCurrentJobApplications(data.inserted.applications, "applied")
          }
          
          resolve(data);
          // dispatch(updateJob(data.inserted))
        })
        .catch((error) => {
          console.log("catch", error.response);
          reject(error);
          return error;
        });
    });
  };
