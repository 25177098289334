import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TalentPoolTable from "../components/talentpool/talentPoolTable";
import { useEffect, useRef } from "react";
import {
  Container,
  Grid,
  GridColumn,
  Button,
  Icon,
  Input,
  Image,
  Checkbox,
  Divider,
  Tab,
  TabPane,
} from "semantic-ui-react";
import TalentFilter from "../components/poolAndSearches/Filter";
import {
  getAllCustomPool,
  setFilterValue,
} from "../actions/action_talent_pool";

import TalentPoolHeader from "../components/talentpool/Header";
import axios from "../api/axiosInstance.js";
import { getHeader } from "../types.js";
import { useParams } from "react-router";
import CustomPoolTable from "../components/poolAndSearches/CustomPoolTable";
import PoolLimitModal from "../components/poolAndSearches/PoolLimitModal";

import "./talentpool.css";
import Swal from "sweetalert2";
import SharedToModal from "../components/poolAndSearches/SharedToModal.jsx";

const initialFilter = {
  title: "",
  date_range: null,
  visibleTo: "",
  created_date: "",
  show_on_qrata_website: [],
};

const getPoolAndSearchesData = async (filter) => {
  try {
    const response = await axios.post(
      "/api/talentpool/custompool/filter",
      filter,
      { headers: getHeader() }
    );
    const data = await response.data.data;
    return data;
  } catch (err) {
    console.log("Error: ", err);
    throw err;
  }
};

const getGlobalValues = async () => {
  try {
    const response = await axios.get("/api/global-values");
    return response.data;
  } catch (err) {
    console.log("error getting global values", err);
    throw err;
  }
};

export default function CustomPoolAndSearches({ isPublic }) {
  const dispatch = useDispatch();

  const selectedPoolId = useRef(null);

  const { sharedpoolId, publicOrCustom } = useParams();

  const [filter, setFilter] = useState(initialFilter);

  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openShare, setOpenShare] = useState({
    open: false,
    poolId: null,
  });
  const [emails, setEmails] = useState([]);
  const [poolLimit, setPoolLimit] = useState(null);
  const userRole = useSelector((state) => state.user.user);

  const onCandTalentCheckBox = (id) => {
    let indexIs = emails.indexOf(id);
    if (indexIs > -1) {
      let emailCopy = [...emails];
      emailCopy.splice(indexIs, 1);
      setEmails(emailCopy);
    } else {
      setEmails([...emails, id]);
    }
  };

  const handleDelete = async (singleData) => {
    const deleteIds = singleData.length > 0 ? singleData : emails;
    await axios
      .put(
        "/api/talentpool/custompool",
        { ids: deleteIds },
        { headers: getHeader() }
      )
      .then((res) => {
        console.log("updated data", data);
        let updatedData = data.filter(
          (item) => !deleteIds.includes(item._id)
        );
        setData(updatedData);
        setEmails([]);
        return Swal.fire({
          title: "Pool(s) has been deleted",
          timer: 2000
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShowPool = (poolId, value) => {
    const activeQrataPools = data.filter(
      (item) => item.show_on_qrata_website
    ).length;
    const poolToToogle = data.find(item => item._id === poolId);
    if (activeQrataPools >= poolLimit && value === false) {
      return Swal.fire({
        title: "Can't activate this pool",
        text: "Pool limit exceeded",
        confirmButtonText: "OK",
      });
    }
    console.log("Pool limit exceeded", poolToToogle);
    if(poolToToogle.no_of_profiles < 10 && value === false) {
      return Swal.fire({
        title: "Can't activate this pool",
        text: "A pool needs at least 10 talents to be activated on Qrata.ai.",
        confirmButtonText: "OK",
      });
    }
    axios
      .put(
        `/api/talentpool/custompool/${poolId}`,
        { show_on_qrata_website: !value },
        { headers: getHeader() }
      )
      .then((res) => {
        let updatedData = data.map((item) => {
          if (item._id === poolId) {
            return {
              ...item,
              show_on_qrata_website: !item.show_on_qrata_website,
            };
          } else {
            return item;
          }
        });
        setData(updatedData);
        setEmails([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTabChange = (index) => {
    setEmails([]);
    setActiveIndex(index);
  } 

  useEffect(() => {
    if (isPublic) {
      if (publicOrCustom === "public") {
        dispatch({ type: "LOADING_API_STATE" });
        axios
          .get(`/api/talentpool/saved-search/${sharedpoolId}`)
          .then((res) => {
            dispatch(setFilterValue(res.data.searchFields));
            setLoading(false);
          });
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const results = await getPoolAndSearchesData(filter);
      const data = await getGlobalValues();
      setPoolLimit(data.pool_limit_on_qrata_website);
      setData(results);
      setLoading(false);
    };
    fetchData();
  }, [JSON.stringify(filter)]);

  return (
    <>
      <TalentPoolHeader isPublic={isPublic} poolAndSearches={true} />

      <Divider fitted />

      <Grid>
        <GridColumn width={16}>
          <div className="secondary-header">
            <div style={{ display: "flex" }}>
              <h3
                className={`header-box ${
                  activeIndex === 0 ? "table-active" : ""
                }`}
                style={{
                  letterSpacing: "0.5px",
                  fontWeight: "400",
                }}
                role="button"
                onClick={() => handleTabChange(0)}
              >
                All Pools & Searches
              </h3>
              <h3
                role="button"
                onClick={() => handleTabChange(1)}
                className={`header-box ${
                  activeIndex === 1 ? "table-active" : ""
                }`}
                style={{
                  letterSpacing: "0.5px",
                  fontWeight: "400",
                }}
              >
                Pools on Qrata.ai
              </h3>
            </div>
            <div className="pool-action-buttons bluecol">
              <Button
                size="small"
                style={{ color: "red" }}
                onClick={handleDelete}
                disabled={emails.length < 1}
              >
                Delete &nbsp; <Icon name="trash alternate" />
              </Button>
              <Button basic size="small" onClick={() => setOpen(true)}>
                Pool limit on Qrata.ai
              </Button>
            </div>
          </div>

          <Divider fitted />
        </GridColumn>

        <GridColumn width={12}>
          <Container fluid style={{ paddingLeft: "1rem", marginTop: "1rem" }}>
            <Grid>
              <GridColumn
                floated="left"
                width={16}
                // style={{ overflow: "none" }}
              >
                { loading ? <div>Loading...</div> : 
                <CustomPoolTable
                  emailIds={emails}
                  onCandTalentCheckBox={onCandTalentCheckBox}
                  isPublic={isPublic}
                  activeIndex={activeIndex}
                  data={data}
                  handleShowPool={handleShowPool}
                  handleDelete={handleDelete}
                  setOpenShare={setOpenShare}
                />
                }
              </GridColumn>
            </Grid>
          </Container>
        </GridColumn>
        <GridColumn width={4}>
          {!isPublic && (
            <TalentFilter
              visible={true}
              filter={filter}
              setFilter={setFilter}
              initialFilter={initialFilter}
            />
          )}
        </GridColumn>
      </Grid>
      {
        openShare.open && (
          <SharedToModal
            open={openShare.open}
            close={() => setOpenShare({ open: false, poolId: null })}
            poolId={openShare.poolId}
          />
        )
      }
      {open && (
        <PoolLimitModal
          open={open}
          close={() => setOpen(false)}
          value={poolLimit}
          setValue={setPoolLimit}
        />
      )}
    </>
  );
}
