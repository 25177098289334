import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  CardContent,
  Image,
  Icon,
  Checkbox,
  Button,
  Table,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Popup,
  TableCell,
  CardHeader,
  CardMeta,
  CardDescription,
} from "semantic-ui-react";
// import Desig from "../../designation.svg";
import { Link, useLocation } from "react-router-dom";
// import { Card, Grid, Image, Icon, Checkbox, Button } from "semantic-ui-react";

import { config } from "../../config";
import { useModalOpen } from "./../../customHooks";
import moment from "moment";
import "./talentpool.css";
import TalentPopup from "./talent-candidate-details";
import MappedPoolCandidateModal from "./MappedPool-candidateModal";
import UserImg from "../../images/user.webp";
import Linkedin from "../../images/svg/linkedin.svg";
import Twitter from "../../images/svg/twitter.svg";
import Portfolio from "../../images/svg/portfolio.svg";
import Github from "../../images/svg/github.svg";
import Bag from "../../images/svg/school-bag.svg";
import Location from "../../images/svg/location.svg";
import Compensation from "../../images/svg/compensation-1.svg";
import YOE from '../../images/svg/yoe.svg';
import "./talentpool.css";
import Swal from "sweetalert2";
import { CurrencyTypeCheck } from "../../OptionData";
// import Ionsda from '../../'

function CandidateCard({
  candidate,
  onCandTalentCheckBox,
  isPublic,
  index,
  filter,
  emailIds,
  poolId,
}) {
  // const FirstName = candidate.first_name.split("");
  const location = useLocation();

  const talentcardModal = useModalOpen();

  const userRole = useSelector((state) => state.user.user?.role);
  const unlockedTalents = useSelector(
    (state) => state.application.unlockedTalent
  );
  const [copiedStatus, setCopiedStatus] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);

  const talents = useSelector((state) => state.talent_pool.talents);

  const url = !isPublic
    ? `/talent_pool/candidate/${
        candidate._id.includes("@") ? candidate.talent_id : candidate._id
      }/bio?${userRole === "client" ? `poolId=${poolId}` : ""}`
    : `/talent_pool/candidate/${
        candidate._id.includes("@") ? candidate.talent_id : candidate._id
      }/bio/sales`;

  const longUrl = `${window.location.origin}/talent_pool/candidate/${candidate._id}/bio/sales`;
  const shortenUrl = async (longUrl) => {
    const apiEndpoint = `https://api.tinyurl.com/create`;
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer l8RVzxtI8phIlj1wv8eMEZlW7rEJSaoBINTmMHUdB2jNT6LbRvYj6c5ZoXvY", // Replace with your TinyURL API token
        },
        body: JSON.stringify({
          url: longUrl,
        }),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      return data.data.tiny_url;
    } catch (error) {
      console.error("Error shortening URL:", error);
      return null;
    }
  };

  // const onHandleClick = async () => {
  //   setShareLoading(true);
  //   const shortenedUrl = await shortenUrl(longUrl);
  //   console.log("shared url", shortenedUrl, longUrl);
  //   navigator.clipboard.writeText(shortenedUrl);
  //   setCopiedStatus(true);
  //   setShareLoading(false);
  //   setTimeout(() => {
  //     setCopiedStatus(false);
  //   }, 1500);
  //   // setCopyText("Copied");
  //   // setTimeout(() => {
  //   //   setCopyText(buttonText || "Copy Link");
  //   // }, 3000);
  // };

  const qrataInsights =
    candidate.qrataInsights?.scores.length > 0 && candidate.qrataInsights?.summary
      ? `Qrata Insights: ${candidate.qrataInsights.summary.replace(/<\/?p>/g, "")}
    ${candidate.qrataInsights.scores
      .map((score) => `${score.dimension}: ${score.comment}\n`)
      .join("")}
    `
      : "";

  const isMobile = window.innerWidth < 768;

  return (
    <>
      {!isMobile ? (
        <>
        {
          !isPublic &&
          <div
            key={candidate._id}
            className="table-cell row-span-2 justify-content-center p-2"
          >
            {!isPublic &&
            candidate.source === "talent" &&
            userRole === "Admin" ? (
              <Table.Cell >
                <Checkbox
                  label=""
                  onChange={() => onCandTalentCheckBox(candidate._id)}
                  checked={emailIds.includes(candidate._id)}
                />
              </Table.Cell>
            ) : !isPublic && candidate.source === "candidate" ? (
              <div className="table-cell row-span-2"></div>
            ) : null}
          </div>
        }
          <div className="table-cell row-span-2 justify-content-center">
            {isPublic ||
            (userRole === "client" &&
              !unlockedTalents.find((id) => id === candidate._id)) ? (
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <Image
                  src={candidate?.profileURL ? candidate?.profileURL : UserImg}
                  className="table-photo"
                  circular
                />
              </div>
            ) : (
              <TalentPopup
                isPublic={isPublic}
                candidate={candidate}
                url={url}
              />
            )}
          </div>
          <div className="table-cell no-border row1">
            <Link
              to={
                userRole === "client" &&
                !unlockedTalents.find((id) => id === candidate._id)
                  ? null
                  : url
              }
              state={{ prev: location.pathname, ind: index }}
            >
              <h4
                className={
                  (userRole === "client" &&
                  !unlockedTalents.find((id) => id === candidate._id)) || !userRole
                    ? "blur"
                    : null
                }
                style={{ color: 'var(--text)', fontWeight: '600'}}
              >
                {candidate.first_name} {candidate.last_name} &nbsp;
                {socialLinkRender(candidate.workLinks)}
              </h4>
            </Link>
          </div>
          <div className="table-cell row1" style={{ color: 'var(--text)' }}>
            {(candidate.location || candidate.currentLocation) && (
              <h4>
                <Icon>
                  <Image src={Location} />
                </Icon>
                <span>
                {candidate.location
                  ? candidate?.location?.split(",")[0]
                  : candidate?.currentLocation?.split(",")[0]
                  ? candidate?.currentLocation?.split(",")[0]
                  : "-"}
                </span>
              </h4>
            )}
          </div>
          <div className="table-cell row1" style={{ color: 'var(--text)' }}>
            {candidate.experience > 0 && (
              <h4 className="">
                <Icon>
                  <Image src={YOE} />
                </Icon>
                <span>
                  {candidate.experience && candidate.experience
                    ? candidate.experience + " " + "years"
                    : "-"}
                </span>
              </h4>
            )}
          </div>
          <div className="table-cell row1" style={{ color: 'var(--text)' }}>
            {filter?.typeofEmployment?.Contract ? (
              <>
                <span>
                  {candidate.preferredSalaryPerHour
                    ? `${candidate.preferredSalaryPerHourCurrency} ${candidate.preferredSalaryPerHour} `
                    : "-" + "/" + candidate.timeCommitment
                    ? `${candidate.timeCommitment} ${candidate.timeCommitmentType}`
                    : "-"}
                </span>
              </>
            ) : (
              candidate.currentSalary > 0 &&
              candidate.preferredSalary > 0 && (
                <h4>
                  <Icon>
                    <Image src={Compensation} />
                  </Icon>
                  <span>{salaryRender(candidate.currentSalary, candidate.preferredSalary, candidate.currentCurrencyType)}</span>
                </h4>
              )
            )}
          </div>
          <div className="table-cell row-span-2 flex-column table-button-container bluecol">
            {candidate.source === "talent" ? (
              <Link to={url} state={{ prev: location.pathname, ind: index }}>
                <Button className="table-button view" fluid>
                  <Icon name="eye" />
                  View
                </Button>
              </Link>
            ) : (
              <MappedPoolCandidateModal candidate={candidate} />
            )}
            {/* {candidate.source == "talent" && (
              <Button
                className="table-button share"
                onClick={onHandleClick}
                loading={shareLoading}
                fluid
              >
                {
                  copiedStatus ? "Copied" : <span>
                    <Icon name="share alternate" />
                    Share
                  </span>
                }
              </Button>
            )} */}
          </div>
          <div className="table-cell col-span-4 flex-column" style={{ paddingBottom: '1rem'}}>
            <p className="role-designation" style={{color: 'var(--text)'}}>
            {candidate.currentDesignation
                    ? candidate.currentDesignation
                    : ""}
                  {candidate.currentCompany && candidate.currentDesignation
                    ? ", " + candidate.currentCompany
                    : candidate.currentCompany
                    ? candidate.currentCompany
                    : ""}
            </p>
            {qrataInsights.length > 0 && (
              <p style={{ color: "#645B5B", marginBottom: '6px' }}>
                {qrataInsights.slice(0, 150)}
                {qrataInsights.length > 0 && (
                  <Link to={url} target="_blank">
                    <span className="bluecol" style={{ fontWeight: '600'}}> ...read more </span>
                  </Link>
                )}
              </p>
            )}
              {
                candidate.skills?.length > 0
                &&
            <p style={{ color: "#645B5B" }}>
              <ul className="skills-list">
                <span style={{ fontSize: "14px" }}>
                  Skills:
                </span>
                { candidate.skills.slice(0, 5).map((skill) => (
                      <li key={skill} className="chip">
                        {skill}
                      </li>
                    ))
                  }
              </ul>
            </p>
              }
            {/* <br /> */}
          </div>
        </>
      ) : (
        <>
          <Card fluid>
            <CardContent>
              <Image
                src={candidate?.profileURL ? candidate?.profileURL : UserImg}
                floated="left"
                size="mini"
                circular
              />
              <CardHeader>
                {/* <h4 style={{ marginBottom: '2px'}}> */}
                <h2 className={
                  (userRole === "client" &&
                  !unlockedTalents.find((id) => id === candidate._id)) || !userRole
                    ? "blur"
                    : null
                }
                style={{ color: 'var(--blue)', fontWeight: '600', margin: 0, textTransform: 'capitalize' }}>
                {candidate.talentName} {socialLinkRender(candidate.workLinks)}
                </h2>
                {/* </h4> */}
                {/* <p style={{ fontSize: '12px !important' }} className="bluecol role-designation">
          {candidate.currentDesignation ? candidate.currentDesignation : ""}
          {candidate.currentCompany ? ", " + candidate.currentCompany : ""}
        </p> */}
                <CardMeta>
                  {candidate.currentDesignation
                    ? candidate.currentDesignation
                    : ""}
                  {candidate.currentCompany && candidate.currentDesignation
                    ? ", " + candidate.currentCompany
                    : candidate.currentCompany
                    ? candidate.currentCompany
                    : ""}
                </CardMeta>
              </CardHeader>
              <CardDescription>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div className="table-cell bluecol row1">
                    <h4>
                      <Icon>
                        <Image src={Location} />
                      </Icon>
                      {candidate.location
                        ? candidate?.location?.split(",")[0]
                        : candidate?.currentLocation?.split(",")[0]
                        ? candidate?.currentLocation?.split(",")[0]
                        : "-"}
                    </h4>
                  </div>
                  <div className="table-cell bluecol row1">
                    <h4 className="">
                      <Icon>
                        <Image src={Bag} />
                      </Icon>
                      <span>
                        {candidate.experience && candidate.experience
                          ? candidate.experience + " " + "years"
                          : "-"}
                      </span>
                    </h4>
                  </div>
                  <div className="table-cell bluecol row1">
                    {filter?.typeofEmployment?.Contract ? (
                      <>
                        <span>
                          {candidate.preferredSalaryPerHour
                            ? `${candidate.preferredSalaryPerHourCurrency} ${candidate.preferredSalaryPerHour} `
                            : "-" + "/" + candidate.timeCommitment
                            ? `${candidate.timeCommitment} ${candidate.timeCommitmentType}`
                            : "-"}
                        </span>
                      </>
                    ) : (
                      <h4>
                        <Icon>
                          <Image src={Compensation} />
                        </Icon>
                        {salaryRender(
                          candidate.currentSalary,
                          candidate.expectedCTC
                        )}
                      </h4>
                    )}
                  </div>
                </div>
                <p style={{ color: "#645B5B" }}>
                  {qrataInsights.slice(0, 50)}
                  {qrataInsights.length > 0 && (
                    <span className="bluecol"> ...read more </span>
                  )}
                </p>
                <p style={{ color: "#645B5B" }}>
                  <span style={{ fontSize: "1.2rem", paddingRight: "5px" }}>
                    Skills:
                  </span>
                  <ul className="skills-list">
                    {candidate.skills
                      ? candidate.skills
                          .slice(0, 5)
                          .map((skill) => <li className="chip">{skill}</li>)
                      : null}
                  </ul>
                </p>
              </CardDescription>
            </CardContent>
            <CardContent extra>
              <div className="ui two buttons">
                {/* <div className="table-cell row-span-2 flex-column p-2 bluecol"> */}
                {candidate.source === "talent" ? (
                  <Link
                    to={url}
                    state={{ prev: location.pathname, ind: index }}
                  >
                    <Button className="table-button view">
                      <Icon name="eye" />
                      View TalentCard
                    </Button>
                  </Link>
                ) : (
                  <MappedPoolCandidateModal candidate={candidate} />
                )}
                {/* {candidate.source == "talent" && (
                  <Button
                    style={{ marginLeft: "10px" }}
                    className="table-button share"
                    onClick={onHandleClick}
                    loading={shareLoading}
                  >
                    {
                  copiedStatus ? "Copied" : <span>
                    <Icon name="share alternate" />
                    Share
                  </span>
                }
                  </Button>
                )} */}

                {/* </div> */}
              </div>
              <div style={{ padding: "10px 0px" }}>
                {candidate.createdAt ? (
                  <div style={{ display: "flex", color: "#B3B3B3" }}>
                    Added on: &nbsp;{" "}
                    <DateDisplay dateString={candidate?.createdAt} />
                  </div>
                ) : null}
                {candidate.lastUpdated ? (
                  <div style={{ display: "flex", color: "#B3B3B3" }}>
                    Last Updated: &nbsp;
                    <DateDisplay dateString={candidate?.lastUpdated} />{" "}
                  </div>
                ) : null}
              </div>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
}

export default CandidateCard;

function socialLinkRender(workLinks = null) {
  if (!workLinks) return null;
  return (
    <>
      {workLinks.linkedinLink ? (
        <Icon>
          <Image
            src={Linkedin}
            href={workLinks.linkedinLink}
            target="_blank"
            className="hover"
          />
        </Icon>
      ) : null}
      {workLinks.githubLink ? (
        <Icon>
          <Image
            src={Github}
            size
            href={workLinks.githubLink}
            target="_blank"
            className="hover"
          />
        </Icon>
      ) : null}
      {workLinks.portfolioLink ? (
        <Icon>
          <Image
            src={Portfolio}
            size="tiny"
            href={workLinks.portfolioLink}
            target="_blank"
            className="hover"
          />
        </Icon>
      ) : null}
      {workLinks.twitterLink ? (
        <Icon>
          <Image
            src={Twitter}
            href={workLinks.twitterLink}
            target="_blank"
            className="hover"
            size
          />
        </Icon>
      ) : null}

      {workLinks.behanceLink ? (
        <Icon>
          <Image
            // src={Behance}
            href={workLinks.behanceLink}
            target="_blank"
            className="hover"
          />
        </Icon>
      ) : null}
      {workLinks.dribblLink ? (
        <Icon>
          <Image
            // src={Dribble}
            href={workLinks.dribblLink}
            target="_blank"
            className="hover"
          />
        </Icon>
      ) : null}
    </>
  );
}



function salaryRender(currentSalary, expectedSalary, currencyType) {
  currentSalary = currentSalary
    ? parseInt(currentSalary).toString() + " " + CurrencyTypeCheck(currencyType || "INR")
    : "";
  expectedSalary = expectedSalary
    ? parseInt(expectedSalary).toString() + " " + CurrencyTypeCheck(currencyType || "INR")
    : "";
  return currentSalary + " - " + expectedSalary;
}

const DateDisplay = ({ dateString }) => {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);

  return <span>{formattedDate}</span>;
};