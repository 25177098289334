import React, { Component } from "react";
import {Label, Icon, Container, Grid, Form, Button, Message, Loader, Input} from "semantic-ui-react";
import Navbar from "./../components/navbar/nav.js";
import Validator from "validator";
import InlineError from "./../messages/InlineError";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { submitSetForgotPassword } from "./../actions/action_auth";
import { config } from "../config.js";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { applyMiddleware } from "redux";
import auth_api from "../api/auth_api.js";

export default function SetForgotPassword(){
  // let success = false;
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const token = params.token;
  console.log("setForgotPassword", params)
  const [data, setData] = useState({
    password:'',
    repassword:'',
  });
  const [ loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [revisible, setRevisible] = useState(false);
  
  const [errors, setErrors] = useState({});
  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name] : e.target.value
    });
    setErrors({});
  }

  const validate = () => {
    const errors = {};
    if (!data.password) errors.password = "This field is required.";
    if (!data.repassword) errors.repassword = "This field is required.";
    return errors;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      
      try {
        const response = await auth_api.user.submitSetForgotPasswordApi(data, token);
        // success = true;
        setSuccess(true);
        setTimeout(() => {
          navigate('/signin');
        }, 3000);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data && error.response.data.error === "Passwords do not match") {
          // Use backend error message
          setErrors({
            password: error.response.data.error,
            repassword: error.response.data.error
          });
        } else {
          setErrors({ token: error.response?.data?.error || "An error occurred" });
        }
      } finally {
        // setLoading(false);
        if (!success) {
          setLoading(false);
        }
      }
    }
  }

  console.log(errors);
  return (
    <div>
        <Navbar />
        <Container>
          <Grid centered columns={2}>
            <Grid.Column>
              <br />
              <br />
              <h1 style={{ color: "#003458" }}>Forgot password</h1>
              <Form onSubmit={onSubmit}>
                <Form.Field>
                  <label style={{ color: "#003458" }}>Password</label>
                  {/* <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={data.password}
                    onChange={onChange}
                  />
                  {errors.password && <InlineError text={errors.password} />} */}
                    <div>
                        <Input
                            icon
                            placeholder="Password"
                            type={visible ? 'text' : 'password'}
                            name="password"
                            value={data.password}
                            onChange={onChange}
                            style={{ background: 'transparent', borderRadius: '8px', width: '100%' }}
                        >
                            <input style={{ padding: '1rem', width: '100%', fontSize: '1.2rem' }} />
                            <Icon
                                name={visible ? 'eye' : 'eye slash'}
                                onClick={() => setVisible((visible) => !visible)}
                                link
                            />
                        </Input>
                        {errors.password && <Label pointing color="red">{errors.password}</Label>}
                    </div>
                </Form.Field>
                <Form.Field>
                  <label style={{ color: "#003458" }}>Re-enter password</label>
                  {/* <input
                    type="password"
                    name="repassword"
                    id="password"
                    placeholder="Re-enter password"
                    value={data.repassword}
                    onChange={onChange}
                  />
                  {errors.repassword && (
                    <InlineError text={errors.repassword} />
                  )} */}
                  <div>
                    <Input
                        icon
                        placeholder="Re-enter password"
                        type={revisible ? 'text' : 'password'}
                        name="repassword"
                        value={data.repassword}
                        onChange={onChange}
                        style={{ background: 'transparent', borderRadius: '8px', width: '100%' }}
                    >
                        <input style={{ padding: '1rem', width: '100%', fontSize: '1.2rem' }} />
                        <Icon
                            name={visible ? 'eye' : 'eye slash'}
                            onClick={() => setRevisible((revisible) => !revisible)}
                            link
                        />
                    </Input>
                    {errors.repassword && <Label pointing color="red">{errors.repassword}</Label>}
                </div>
                </Form.Field>
                <Button
                  floated="right"
                  style={{ color: "white", background: "#21C8AA" }}
                  
                >
                  {loading ? (
                <Loader size="tiny" active inline="centered" />
              ) : (
                "Save"
              )}
                </Button>
              </Form>
              <br />
              {success && (
                <Message positive>
                  <Message.Header>Password reset</Message.Header>
                  <p>Password has been reset successfully.</p>
                </Message>
              )}
              {errors.token && (
                <Message negative>
                  <Message.Header>Password reset failed</Message.Header>
                  <p>Please go to forgot password</p>
                </Message>
              )}
            </Grid.Column>
          </Grid>
        </Container>
      </div>
  )
}

