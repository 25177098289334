import { useEffect, useRef, useState } from "react";

const AutoRefreshHandler = () => {
  const token = localStorage.getItem('accessToken');
  const timeoutRef = useRef(null);
  const [timerExpired, setTimerExpired] = useState(false);

  const resetTimer = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setTimerExpired(false); // Reset timer status
    timeoutRef.current = setTimeout(() => {
      setTimerExpired(true); // Mark timer as expired
    }, 1 * 60 * 60 * 1000); // 4 hours (adjust as needed)
  };

  useEffect(() => {
    // Start the inactivity timer
    

    if(token) {
        resetTimer();
        const events = ["mousemove", "keydown", "scroll", "click"];
    const handleVisibilityChange = () => {
        if (document.visibilityState === "visible" && timerExpired) {
          window.location.reload(); // Refresh when user returns after timer expired
        }
      };
        events.forEach((event) => window.addEventListener(event, resetTimer));
    
        // Handle tab visibility change
        
        document.addEventListener("visibilitychange", handleVisibilityChange);
    }

    return () => {
      // Cleanup event listeners & timeout on unmount
      const events = ["mousemove", "keydown", "scroll", "click"];
    const handleVisibilityChange = () => {
        if (document.visibilityState === "visible" && timerExpired) {
          window.location.reload(); // Refresh when user returns after timer expired
        }
      };
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [timerExpired, token]);

  return null;
};

export default AutoRefreshHandler;
