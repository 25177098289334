import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { Document, Page, pdfjs } from 'react-pdf'
import pdfjsWorker from 'file-loader!pdfjs-dist/build/pdf.worker';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
// import Filesss from "../../../src/Basic_Resume.docx";
import './resume-render.css'
import EditResumeModal from "../New-TalentCard/BasicDetails/PersonalDetails/ResumeModal/EditResumeModal";
import { useSelector } from "react-redux";


pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker; 

export default function ResumeRender({ resume }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { role } = useSelector((state) => state.user.user);
   const { individual_candidate } = useSelector((state) => state.application);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  
  if (resume === "" || resume === null || resume === undefined) {
    return <div>No resume found</div>;
  }
  const docs = [
    { uri: resume}, // Remote file
  ];

  const docType = resume.split('.').pop();
  console.log("file url", docType)
  return (
    <React.Fragment>
      <div className="resume-edit-button" style={{ textAlign: "right", display: "flex"}}>
       
        <Button
          href={resume}
          style={{ background: "#21C8AA", color: "white" }}
        >
          Download
        </Button>
        {role == "Admin" ? (
                    <EditResumeModal talentId={individual_candidate.bio._id} />
                  ) : null}
      </div>
      <br />

      <center>
      {
        docType == 'jpg' || docType == 'jpeg' ||  docType == 'png' || docType == 'svg' ? 
        <img src={resume} alt="Certificate" /> : null
      }
      {
        <div>
      <Document file={resume} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
      </Document>
    </div>
      }
      </center>
    </React.Fragment>
  );
}
