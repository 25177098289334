import React, { Component } from "react";
import { Container, Grid, Form, Button, Message, Loader } from "semantic-ui-react";
import Navbar from "./../components/navbar/nav.js";
import Validator from "validator";
import InlineError from "./../messages/InlineError";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { submitForgotPassword } from "./../actions/action_auth";

class ForgotPassword extends Component {
  state = {
    data: {
      email: "",
    },
    errors: {},
    success: false,
    loading: false,
  };

  onChange = (e) => {
    this.setState({ data: { ...this.state.data, email: e.target.value } });
  };


  onSubmit = () => {
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({...this.state, loading: true });
      this.props
        .submitForgotPassword(this.state.data)
        .then((response) => {
          this.setState({ success: true, data: { email: "" }, errors: {}, loading: false });
        })
        .catch((error) => {
          console.log(error.response);
          this.setState({
            ...this.state,
            loading: false,
            errors: {
              email: error.response.data.email,
            },
          });
        });
    }
  };

  validate = (data) => {
    const errors = {};
    if (!Validator.isEmail(data.email)) errors.email = "Invalid email";
    return errors;
  };


  render() {
    const { data, errors } = this.state;

    return (
      <div>
        <Navbar />
        <Container>
          <Grid centered columns={2}>
            <Grid.Column>
              <br />
              <br />
              <h1 style={{ color: "#003458" }}>Forgot password</h1>
              <Form onSubmit={this.onSubmit}>
                <Form.Field error={!!errors.email}>
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={data.email}
                    onChange={this.onChange}
                  />
                  {errors.email && <InlineError text={errors.email} />}
                </Form.Field>
                <Button
                  floated="right"
                  primary
                  style={{ background: "#21C8AA", color: "white" }}
                >
                  {/* Send email */}
                  {this.state.loading ? (
                    <Loader size="tiny" active inline="centered" />
                  ): 
                  (
                    "Send email"
                  )}
                </Button>
              </Form>
              <br />
              {this.state.success && (
                <Message positive>
                  <Message.Header style={{ color: "#003458" }}>
                    Mail sent.
                  </Message.Header>
                  <p>A mail has been sent, please click to reset password.</p>
                </Message>
              )}
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  submitForgotPassword: PropTypes.func.isRequired,
};

export default connect(null, { submitForgotPassword })(ForgotPassword);
